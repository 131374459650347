import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, FlatList, TouchableOpacity, View, SafeAreaView } from 'react-native';
import * as CmnUtil from './CmnUtil';
import licensesJsonSrc from '../license.json';

const ScreenLicense = ({ navigation }: { navigation: any }) => {

  type Lisence = {
    nameVersion: string
    name: string
    version: string
    owner: string
    type: string
    repository: string
  }
  const [licenseData, setLicenseData] = useState<Lisence[]>([]);

  useEffect(() => {
    const licensesJson = JSON.parse(JSON.stringify(licensesJsonSrc));
    const tmpData: Lisence[] = [];
    for (const key in licensesJson) {
      const license = licensesJson[key];
      // プライベートライブラリは非表示
      if (license.private) {
        continue;
      }
      // ライブラリ内のサブライブラリは非表示
      if (key.charAt(0) === '@') {
        continue;
      }
      // ライブラリに内包されているものはURLがないので非表示
      if (license.repository == undefined) {
        continue;
      }
      // バージョンなしは非表示
      const tmp = key.match(/(.+)@(.+)$/);
      if (!tmp) {
        continue;
      }

      tmpData.push({
        nameVersion: key,
        name: tmp[1],
        version: tmp[2],
        owner: license.publisher,
        type: license.licenses,
        repository: license.repository,
      });
    }
    setLicenseData([...tmpData]);
  }, []);

  // ライセンスクリック
  const onPressItem = (item: Lisence) => {
    if (item.repository != undefined) {
      CmnUtil.moveWebPage(item.repository);
    }
  }

  // ライセンス1行分描画
  const renderItem = ({ item }: { item: Lisence }) => {
    return (
      <View>
        <TouchableOpacity style={{ padding: 10, borderBottomWidth: 1, borderColor: '#bdbdbd' }}
          onPress={() => onPressItem(item)}>
          <Text>{item.nameVersion} {item.owner} ({item.type})</Text>
          <Text style={{ color: 'gray' }}>{item.repository}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={licenseData}
        renderItem={renderItem}
        keyExtractor={(item: Lisence, index: number) => index.toString()}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
export default ScreenLicense;

import { Platform, Linking } from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import * as FileSystem from 'expo-file-system';
import { WToast } from 'react-native-smart-tip';

// 日時フォーマット
// yyyy:MM:dd HH:mm:ssをyyyy/MM/dd HH:mm:ssに変換する
export const formatDateTime = (date: string) => {
  if (date == null || date.length < 10) {
    return getCurrentDateTime();
  }
  return date.substring(0, 4) + '/' + date.substring(5, 7) + '/' + date.substring(8);
}

// 日時フォーマット
// Date型をyyyy/MM/ddに変換する
export const formatDate = (date: Date) => {
  if (date == undefined) {
    return '';
  } else {
    const yyyy = date.getFullYear();
    const MM = ('0' + (date.getMonth() + 1)).slice(-2);
    const dd = ('0' + date.getDate()).slice(-2);
    return yyyy + '/' + MM + '/' + dd;
  }
}

// 日時フォーマット
// yyyy/MM/ddをDate型に変換する
export const formatToDate = (date: string) => {
  if (date == undefined || date.length < 10) {
    return new Date();
  }
  const tmp = date.split('/');
  if (tmp.length != 3) {
    return new Date();
  }
  const yyyy = Number(tmp[0]);
  const MM = Number(tmp[1]) - 1;
  const dd = Number(tmp[2]);
  return new Date(yyyy, MM, dd);
}

// 現在時刻取得
export const getCurrentDateTime = () => {
  const current = new Date();
  const yyyy = current.getFullYear();
  const MM = ('0' + (current.getMonth() + 1)).slice(-2);
  const dd = ('0' + current.getDate()).slice(-2);
  const HH = ('0' + current.getHours()).slice(-2);
  const mm = ('0' + current.getMinutes()).slice(-2);
  const ss = ('0' + current.getSeconds()).slice(-2);

  return yyyy + '/' + MM + '/' + dd + ' ' + HH + ':' + mm + ':' + ss;
}

// 配列要素マージ
export const mergeList = (base: string[], add: string[]) => {
  for (let i = 0; i < add.length; i++) {
    const index = base.indexOf(add[i]);
    if (index < 0) {
      base.push(add[i]);
    }
  }
}

// メッセージ表示
export const appMsg = async (msg: string) => {
  WToast.show({
    data: msg,
    textColor: 'white',
    backgroundColor: '#444444',
    duration: WToast.duration.SHORT,
    position: WToast.position.BOTTOM,
  });
}

// エラーメッセージ表示
export const appAlert = async (msg: string) => {
  WToast.show({
    data: msg,
    textColor: 'white',
    backgroundColor: '#cc2f18',
    duration: WToast.duration.LONG,
    position: WToast.position.BOTTOM,
  });
}

// パーセントフォーマット
// 0.2345を23.6%に変換する
export const formatRatio = (ratio: number) => {
  return (Math.round(ratio * 1000) / 10).toString() + '%';
}

// 90度反転しているかチェック
export const xyInversion = (orientation: number) => {
  const inversion = [5, 6, 7, 8];
  return inversion.indexOf(orientation) > 0 ? true : false;
}

// 画像圧縮
export const compressImage = async (uri: string) => {
  const mbyte = 1024 * 1024;
  let beforeLength = uri.length;
  let rate = 0.7;
  let threshold = 3 * mbyte
  let actions: any = []

  // 圧縮率の算出
  if (Platform.OS !== 'web') {
    const fileInfo = await FileSystem.getInfoAsync(uri, { size: true });
    beforeLength = fileInfo.size! * 1.3;
  }

  // 3MB程度になるように比率を指定（3MB以下であれば推奨値85%、それ以上であれば単純計算）
  rate = threshold / beforeLength > 1.0 ? 0.85 : threshold / beforeLength

  // 画像圧縮
  let tmp = await ImageManipulator.manipulateAsync(
    uri,
    actions,
    { compress: rate, format: ImageManipulator.SaveFormat.JPEG, base64: true }
  );

  const afterlength = tmp.base64!.length;

  console.log('============画像圧縮============');
  console.log('befoer   :' + beforeLength);
  console.log('after    :' + afterlength);
  console.log('compress :' + rate);
  console.log('圧縮率    :' + (tmp.base64!.length / beforeLength));
  console.log('width    :' + tmp.width);
  console.log('height   :' + tmp.height);
  console.log('================================');


  // 圧縮後に規程サイズを超える場合
  if (afterlength > threshold) {
    console.log("after size over threshold! recompressing...");

    // リサイズ設定
    actions = [{ resize: { width: tmp.width / 2 } }];
    rate = threshold / afterlength;

    console.log("rate:" + rate);

    // 画像再圧縮
    tmp = await ImageManipulator.manipulateAsync(
      uri,
      actions,
      { compress: rate, format: ImageManipulator.SaveFormat.JPEG, base64: true }
    );

    console.log("after length:" + tmp.base64!.length);
    console.log("after width:" + tmp.width + " x " + tmp.height);
  }
  return {
    uri: tmp.uri,
    width: tmp.width,
    height: tmp.height,
    //base64: Platform.OS !== 'web' ? tmp.base64! : tmp.base64!.split(',')[1],
    base64: tmp.base64!
  };
}

// ファイルコピー
export const makeDirectoryAsync = async (dir: string) => {
  await FileSystem.makeDirectoryAsync(FileSystem.documentDirectory + dir, { intermediates: true });
}

// ファイルコピー
export const copyFile = async (from: string, filename: string) => {
  await FileSystem.copyAsync({ from: from, to: FileSystem.documentDirectory + filename });
  return FileSystem.documentDirectory + filename;
}

// ファイル削除
export const deleteFile = async (uri: string) => {
  await FileSystem.deleteAsync(uri, { idempotent: true });
}

// GPS情報取得
export const getGpsInfo = (exif: any) => {
  if (exif.GPSLatitude == undefined || exif.GPSLatitudeRef == undefined || exif.GPSLongitude == undefined || exif.GPSLongitudeRef == undefined) {
    return {
      lat: undefined,
      lon: undefined
    };
  }
  return {
    lat: exif.GPSLatitude,
    lon: exif.GPSLongitude,
  };
}
// GPS情報取得(度分秒形式)
export const getGpsInfo2 = (exif: any) => {
  if (exif.GPSLatitude == undefined || exif.GPSLatitudeRef == undefined || exif.GPSLongitude == undefined || exif.GPSLongitudeRef == undefined) {
    return {
      lat: undefined,
      lon: undefined
    };
  }
  if (exif.GPSLatitude.length != 3 || exif.GPSLongitude.length != 3) {
    return {
      lat: undefined,
      lon: undefined
    };
  }
  return {
    lat: (exif.GPSLatitude[0] + (exif.GPSLatitude[1] / 60) + (exif.GPSLatitude[2] / 3600)) * (exif.GPSLatitudeRef == 'N' ? 1 : -1),
    lon: (exif.GPSLongitude[0] + (exif.GPSLongitude[1] / 60) + (exif.GPSLongitude[2] / 3600)) * (exif.GPSLongitudeRef == 'E' ? 1 : -1),
  };
}

// Webページ遷移
export const moveWebPage = (url: string) => {
  if (Platform.OS == 'web') {
    window.open(url);
  } else {
    Linking.openURL(url);
  }
}

// ローディング表示
let loadingFunc: any = null;
export const setLoadingFunc = (func: Function) => {
  loadingFunc = func;
}
export const startLoading = () => {
  loadingFunc(true);
}
export const endLoading = () => {
  loadingFunc(false);
}

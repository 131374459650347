import * as AppConst from './AppConst';
import * as AuthControl from './AuthControl';
import * as SettingData from './SettingData';

// 型定義
export type Attributes = {
  category: string
  plant: string
  part?: string
}
export type Image = {
  filename: string
  data: string
  lat?: number
  lon?: number
  datetime: string
  answer?: string
}

// 診断API実行
export const dianosis = async (p_attributes: Attributes, p_image: Image, success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.dianosisUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type':'application/json',
      token: AuthControl.getToken(),
      mock: "False",
    },
    body: JSON.stringify({
      assets: [
        {
          images: [
            p_image,
          ],
          prefectures: SettingData.getPrefectures(),
          attributes: convertAttributes(p_attributes),
        }
      ],
    }),
  })
    .then(response => {
      return response.clone().json();
    })
    .then(responseJson => {
      success(responseJson);
    })
    .catch(error => {
      console.log(error);
      console.error(error);
      fail();
    });
};


// 診断API実行
export const ppd_dict = async (pest_disease: string, plant: string, success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.ppd_dictUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      token: AuthControl.getToken(),
      mock: "False",
    },
    body: JSON.stringify({
      assets: [
        {
          plant: convertPlant(plant),
          pest_disease: pest_disease,
        }
      ],
    }),
  })
    .then(response => {
      return response.clone().json();
    })
    .then(responseJson => {
      success(responseJson);
    })
    .catch(error => {
      console.log(error);
      console.error(error);
      fail();
    });
};

//診断API実行
export const pesticide_details = async (pest_disease: string, plant: string, num: string, success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.pesticide_detailsUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      token: AuthControl.getToken(),
      mock: "False",
    },
    body: JSON.stringify({
      assets: [
        {
          plant: convertPlant(plant),
          pest_disease: pest_disease,
          num: num,
        }
      ],
    }),
  })
    .then(response => {
      return response.clone().json();
    })
    .then(responseJson => {
      success(responseJson);
    })
    .catch(error => {
      console.log(error);
      console.error(error);
      fail();
    });
};

// // 診断API実行
// export const dianosis = async (p_attributes: Attributes, p_image: Image, success: Function, fail: Function) => {
//   // HTTPリクエスト→json直接
//   var json = require('./response.json');
//   success(json);
// };

// export const ppd_dict = async (pest_disease: string, plant: string, success: Function, fail: Function) => {
//   // HTTPリクエスト→json直接
//   var json = require('./Ppdresponse.json')
//   success(json);
// };

// export const pesticide_details = async (pest_disease: string, plant: string, num: string, success: Function, fail: Function) => {
//   // HTTPリクエスト→json直接
//   var json = require('./pesticideresponse.json')
//   success(json);
// };

// 虫害->害虫変換
// ダイズ（エダマメ）->ダイズ変換
const convertAttributes = (p_attributes: Attributes) => {
  let tmp: Attributes = {
    category: p_attributes.category == '虫害' ? '害虫' : p_attributes.category,
    plant: p_attributes.plant == 'ダイズ（エダマメ）' ? 'ダイズ' : p_attributes.plant,
  };
  if (p_attributes.part) {
    tmp.part = p_attributes.part;
  }
  return tmp;
}

// ダイズ（エダマメ）->ダイズ変換
const convertPlant = (plant: string) => {
  let tmp: string = plant == 'ダイズ（エダマメ）' ? 'ダイズ' : plant
  return tmp;
}

// マスタ情報取得API実行
export const getMaster = async (success: Function, fail: Function) => {
  // HTTPリクエスト
  fetch(AppConst.getMasterUrl, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(responseJson => {
      //console.log(responseJson);
      success(responseJson);
    })
    .catch(error => {
      //console.error(error);
      fail();
    });
};

import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, FlatList, Image, TouchableOpacity, View, SafeAreaView } from 'react-native';
import IconImages from './IconImages';
import * as AppConst from './AppConst';
import * as SettingData from './SettingData';
import * as CmnUtil from './CmnUtil';

const ScreenPrefectures = ({ navigation }: { navigation: any }) => {
  const [prefectures, setPrefectures] = useState(SettingData.getPrefectures());

  // モーダル選択内容
  const [selectModal, setSelectModal] = useState(false);

  // 都道府県
  const prefecturesList = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ];

  // 決定ボタンクリック
  const onClickDecision = () => {
    if (prefectures.length > 0) {
      SettingData.setPrefectures(prefectures, () => {
        CmnUtil.appMsg('撮影場所を設定しました。');
        navigation.goBack();
      }, () => { });
    } else {
      CmnUtil.appAlert('撮影場所は必須入力です。');
    }
  }

  // モーダル表示
  const renderItemFillter = ({ item, index }: { item: any, index: number }) => {
    return (
      <TouchableOpacity onPress={() => selectItem(item)}
        style={[{ padding: 10, borderColor: '#bdbdbd' }, index !== prefecturesList!.length - 1 && { borderBottomWidth: 1 }]} >
        <Text style={[AppConst.styles.fontSizeLL, { paddingBottom: 5 }]}>{item}</Text>
      </TouchableOpacity>
    );
  }

  // モーダル内アイテム選択
  const selectItem = (item: string) => {
    setPrefectures(item);
    setSelectModal(false);
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contents}>
        <Text>撮影場所の都道府県を選択してください。</Text>
      </View>

      {/* プルダウン表示 */}
      <TouchableOpacity style={{ width: '90%', paddingTop: 20, borderBottomWidth: 1, flexDirection: 'row', marginLeft: '5%', marginRight: '5%' }}
        onPress={() => { setSelectModal(true) }}>
        <Text style={[AppConst.styles.fontSizeL, styles.dropdownText]}>{prefectures}</Text>
        <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
      </TouchableOpacity>

      {/* 決定 */}
      <View style={[styles.contents, { paddingTop: 40 }]}>
        <TouchableOpacity
          style={{ backgroundColor: AppConst.defaultColor, width: '100%', borderRadius: 10, height: 50, marginTop: 5 }}
          onPress={onClickDecision}>
          <View style={styles.center}>
            <Text style={styles.decisionButton}>決定</Text>
          </View>
        </TouchableOpacity>
      </View>

      {/* 選択ダイアログ */}
      {selectModal && <TouchableOpacity style={styles.modal} activeOpacity={1.0} onPress={() => { setSelectModal(false) }}>
        <FlatList
          style={{ width: '90%', height: '90%', marginTop: 150, marginBottom: 150, backgroundColor: 'white', borderRadius: 5 }}
          data={prefecturesList}
          renderItem={renderItemFillter}
          keyExtractor={(item: any, index: number) => index.toString()}
        />
      </TouchableOpacity>}

    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  decisionButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  modal: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  dropdownText: {
    textAlignVertical: 'bottom',
    width: '85%'
  },
  dropdownImage: {
    position: 'absolute',
    right: 10,
    bottom: 5,
    width: 20,
    height: 20
  },
});
export default ScreenPrefectures;

import React, { useState } from 'react'
import { StyleSheet, View, TextInput, TouchableOpacity, Text, SafeAreaView, ScrollView, ActivityIndicator } from 'react-native'
import * as CmnUtil from './CmnUtil';
import * as AppConst from './AppConst';
import * as AuthControl from './AuthControl';

const ScreenResetPassword = ({ navigation }: { navigation: any }) => {

  // 状態
  const phaseList = { confirmUsername: true, confirmCodeAndNewPassword: false };
  const [phase, setPhase] = useState<boolean>(phaseList.confirmUsername);

  // ユーザー名
  const [user, setUser] = useState('');
  // 確認コード
  const [confirmCode, setConfirmCode] = useState('');
  // 新パスワード
  const [new_password, setNewPassword] = useState('');

  // 確認コード送信
  const sendConfirmCode = async () => {
    if (user.length == 0) {
      CmnUtil.appAlert('ユーザー名は必須入力です。');
      return;
    }

    CmnUtil.startLoading();
    AuthControl.forgotPassword(user,
      () => {
        // 正常時
        CmnUtil.endLoading();
        setPhase(phaseList.confirmCodeAndNewPassword);
      },
      (status: AuthControl.AuthResult) => {
        // 異常時
        switch (status) {
          case AuthControl.AuthResult.UserMismatch:
            CmnUtil.appAlert('ユーザー名が見つかりません。');
            break;
          default:
            CmnUtil.appAlert('コード送信に失敗しました。');
            break;
        }
        CmnUtil.endLoading();
      });
  }

  // パスワード登録
  const sendNewPassword = async () => {
    if (confirmCode.length == 0) {
      CmnUtil.appAlert('確認コードは必須入力です。');
      return;
    }
    if (new_password.length == 0) {
      CmnUtil.appAlert('新しいパスワードは必須入力です。');
      return;
    }

    CmnUtil.startLoading();
    AuthControl.forgotPasswordSubmit(user, confirmCode, new_password,
      () => {
        // 正常時
        CmnUtil.endLoading();
        CmnUtil.appMsg('新しいパスワードを登録しました。');
        navigation.goBack();
      },
      (status: AuthControl.AuthResult) => {
        // 異常時
        switch (status) {
          case AuthControl.AuthResult.CodeMismatch:
            CmnUtil.appAlert('無効な確認コードです。もう一度お試しください。');
            break;
          case AuthControl.AuthResult.InvalidParameter:
            CmnUtil.appAlert('パスワードの形式が無効です。');
            break;
          default:
            CmnUtil.appAlert('パスワード登録に失敗しました。');
            break;
        }
        CmnUtil.endLoading();
      });
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contents}>
        <Text style={styles.itemText}>ユーザー名</Text>
        <TextInput
          style={styles.textInput}
          placeholder='ユーザー名を入力してください'
          secureTextEntry={false}
          textContentType='username'
          autoCapitalize='none'
          onChangeText={text => setUser(text)}
          value={user} />

        {phase && <TouchableOpacity
          style={{ backgroundColor: AppConst.defaultColor, width: '100%', borderRadius: 10, height: 50, marginTop: 80 }}
          onPress={sendConfirmCode}>
          <View style={styles.center}>
            <Text style={styles.decisionButton}>登録メールアドレスにコードを送信</Text>
          </View>
        </TouchableOpacity>}

        {!phase && <View>
          <Text style={styles.itemText}>確認コード</Text>
          <TextInput
            style={styles.textInput}
            placeholder='確認コードを入力してください'
            secureTextEntry={false}
            textContentType='oneTimeCode'
            autoCapitalize='none'
            onChangeText={text => setConfirmCode(text)}
            value={confirmCode}
          />

          <Text style={styles.itemText}>新しいパスワード</Text>
          <TextInput
            style={styles.textInput}
            placeholder='新しいパスワードを入力してください'
            secureTextEntry={true}
            textContentType='password'
            autoCapitalize='none'
            onChangeText={text => setNewPassword(text)}
            value={new_password}
          />

          <TouchableOpacity
            style={{ backgroundColor: AppConst.defaultColor, width: '100%', borderRadius: 10, height: 50, marginTop: 80 }}
            onPress={sendNewPassword}>
            <View style={styles.center}>
              <Text style={styles.decisionButton}>新しいパスワードを登録</Text>
            </View>
          </TouchableOpacity>
        </View>}

      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  decisionButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  itemText: {
    marginTop: 20,
    fontSize: AppConst.styles.fontSizeS.fontSize,
  },
  textInput: {
    height: 40,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#cccccc',
    padding: 5,
  },
});

export default ScreenResetPassword

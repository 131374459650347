import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, View, SafeAreaView, } from 'react-native';


const ScreenPesticideDetails = ({ route, navigation }: { route: any, navigation: any }) => {

  const [active_ingredient, setActive_ingredient] = useState<{ key: string; active_ingredient: string; }[]>([]);
  const [other_ingredient, setOther_ingredient] = useState<{ key: string; other_ingredient: string; }[]>([]);
  const [active_ingredients, setActive_ingredients] = useState<{ key: string; active_ingredients: string; }[]>([]);
  const [other_ingredients, setOther_ingredients] = useState<{ key: string; other_ingredients: string; }[]>([]);
  const tmp = route.params.result.details[0];
  const key = Object.keys(tmp.Application_注意)
  const value = Object.values(tmp.Application_注意)

  useEffect(() => { 
    const tmp_active_ingredient = [];
    const tmp_other_ingredient = [];
    const tmp_active_ingredients = [];
    const tmp_other_ingredients = [];
    let count = 0;
    let count2 = 0;
    let count3 = 0;
    if(tmp.Contents_成分.length == 2){
      for (let i = 0; i < tmp.Contents_成分[0].有効成分.length; i++) {
        tmp_active_ingredient[i] = { key: i.toString(), active_ingredient: tmp.Contents_成分[0].有効成分[i]};
      }
      for (let i = 0; i < tmp.Contents_成分[1].その他成分.length; i++) {
        tmp_other_ingredient[i] = { key: i.toString(), other_ingredient: tmp.Contents_成分[1].その他成分[i]};
      }
    }else{
      const keyList = Object.keys(tmp.Contents_成分)
      for (let key in keyList){
        if(Object.keys(tmp.Contents_成分[key])[0] != "その他成分"){
          for (let i = 0; i < tmp.Contents_成分[count].有効成分.length; i++) {
            tmp_active_ingredient[count2] = { key: i.toString(), active_ingredient: tmp.Contents_成分[count].有効成分[i]};
            count2 = count2 + 1
          }
        }else{
          for (let k = 0; k < tmp.Contents_成分[count].その他成分.length; k++) {
            tmp_other_ingredient[count3] = { key: k.toString(), other_ingredient: tmp.Contents_成分[count].その他成分[k]};
            count3 = count3 + 1
          }
        }
        count = count + 1
      }
    }
    setActive_ingredient(tmp_active_ingredient);
    setOther_ingredient(tmp_other_ingredient);
    // setActive_ingredients(tmp_active_ingredients);
    // setOther_ingredients(tmp_other_ingredients);
  }, []);

  const contentsList = () => {
    return(
      <View style={[styles.container, styles.contents]}>
        <Text>有効成分：</Text>
          {active_ingredient.map((item) => {
            return (
              <View key={item.key} style={styles.contents}>
                <Text>{item.active_ingredient}</Text>
              </View>
            );
          })}
          <Text>その他成分：</Text>
          {other_ingredient.map((item) => {
            return (
              <View key={item.key} style={styles.contents}>
                <Text>{item.other_ingredient}</Text>
              </View>
            );
          })}
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>

      <View style={{ borderBottomWidth: 3, borderBottomColor: "black" }}>
        <Text style={{ fontSize: 24, borderBottomColor: '#4a4a4a' }}>{route.params.pesticide_name}</Text>
      </View>

      <View>
        <Text style={styles.title}>適用部:</Text>
      </View>

      <View style={styles.contents}>
        <Text>くん蒸時間：{tmp.Application_くん蒸時間}</Text>
        <Text>くん蒸温度：{tmp.Application_くん蒸温度}</Text>
        <Text>作物名：{tmp.Application_作物名}</Text>
        <Text>作目名：{tmp.Application_作目名}</Text>
        <Text>使用方法：{tmp.Application_使用方法}</Text>
        <Text>使用時期：{tmp.Application_使用時期}</Text>
        <Text>使用液量：{tmp.Application_使用液量}</Text>
        <Text>使用濃度：{tmp.Application_使用濃度}</Text>
        <Text>使用目的：{tmp.Application_使用目的}</Text>
        <Text>使用量：{tmp.Application_使用量}</Text>
        <Text>希釈倍数：{tmp.Application_希釈倍数}</Text>
        <Text>希釈倍数又は使用量：{tmp.Application_希釈倍数又は使用量}</Text>
        <Text>希釈水量：{tmp.Application_希釈水量}</Text>
        <Text>本剤の使用回数：{tmp.本剤の使用回数}</Text>
        <Text>注意：{key}:{value}</Text>
        <Text>登録番号：{tmp.Application_登録番号}</Text>
        <Text>薬量：{tmp.Application_薬量}</Text>
        <Text>適用土壌：{tmp.Application_適用土壌}</Text>
        <Text>適用地帯：{tmp.Application_適用地帯}</Text>
        <Text>適用場所：{tmp.Application_適用場所}</Text>
        <Text>適用害獣名：{tmp.Application_適用害獣名}</Text>
        <Text>適用病害虫名：{tmp.Application_適用病害虫名}</Text>
        <Text>適用農薬名：{tmp.Application_適用農薬名}</Text>
        <Text>適用雑草名：{tmp.Application_適用雑草名}</Text>
      </View>

      <View>
        <Text style={styles.title}>基本部:</Text>
      </View>

      <View style={styles.contents}>
        <Text>剤型：{tmp.Basic_剤型}</Text>
        <Text>失効：{tmp.Basic_失効}</Text>
        <Text>失効年月日：{tmp.Basic_失効年月日}</Text>
        <Text>失効年月日西暦：{tmp.Basic_失効年月日西暦}</Text>
        <Text>失効理由：{tmp.Basic_失効理由}</Text>
        <Text>物理的化学的性状：{tmp.Basic_物理的化学的性状}</Text>
        <Text>用途：{tmp.Basic_用途}</Text>
        <Text>登録年月日：{tmp.Basic_登録年月日}</Text>
        <Text>登録年月日西暦：{tmp.Basic_登録年月日西暦}</Text>
        <Text>登録番号：{tmp.Basic_登録番号}</Text>
        <Text>被害防止方法及び注意事項：{tmp.Basic_被害防止方法及び注意事項}</Text>
        <Text>製剤毒性：{tmp.Basic_製剤毒性}</Text>
        <Text>農薬の使用期限：{tmp.Basic_農薬の使用期限}</Text>
        <Text>農薬の名称：{tmp.Basic_農薬の名称}</Text>
        <Text>農薬の種類：{tmp.Basic_農薬の種類}</Text>
        <Text>農薬登録を有している者の住所又は所在地：{tmp.Basic_農薬登録を有している者の住所又は所在地}</Text>
        <Text>農薬登録を有している者の氏名又は名称：{tmp.Basic_農薬登録を有している者の氏名又は名称}</Text>
        <Text>農薬登録を有している者の氏名又は名称及び住所又は所在地：{tmp.Basic_農薬登録を有している者の氏名又は名称及び住所又は所在地}</Text>
      </View>

      <View>
        <Text style={styles.title}>成分部:</Text>
      </View>

      <View style={styles.contents}>
        <Text>成分：</Text>
        {contentsList()}
      </View>

      <View style={styles.contents}>
        <Text>登録番号：{tmp.Contents_登録番号}</Text>
      </View>

      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    width: '100%',
    fontSize: 24,
  },
  contents: {
    width: '100%',
    marginStart: 30,
  },
});


export default ScreenPesticideDetails;

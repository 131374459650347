import { StyleSheet } from 'react-native';

// 診断URL
export const dianosisUrl = 'https://0umbbc9gze.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv3.0/api/diagnosis';

// 詳細URL
export const ppd_dictUrl = 'https://0umbbc9gze.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv3.0/api/cooperation/ppd_dict';

// 農薬URL
export const pesticide_detailsUrl = 'https://0umbbc9gze.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv3.0/api/cooperation/pesticide_details';

// マスタ情報取得URL（取得できなければ本ファイルの情報を使用）
export const getMasterUrl = 'https://0umbbc9gze.execute-api.ap-northeast-1.amazonaws.com/dev/appsrvv2.0/api/master';

// Web版の診断時ファイル名
export const filenameWeb = 'webImage.jpg';

// 部位（トマト病害）
export let partTomatoDd = [
  '葉（表）',
  '葉（裏）',
  '果実',
  '根',
];
// 部位（トマト虫害）
export let partTomatoPest = [
  '葉',
  '果実',
];
// 部位（キュウリ病害）
export let partCucumberDd = [
  '葉（表）',
  '葉（裏）',
  '花',
  // '果実',
  // 'つる',
  // '茎',
  // '茎頂部',
  // '地際部',
  // '全体',
];
// 部位（キュウリ虫害）
export let partCucumberPest = [
  // '葉（表）',
  // '葉（裏）',
  '葉',
  '花',
  '果実',
  // 'つる',
  // '茎',
  // '茎頂部',
  // '地際部',
  // '全体',
];
// 部位（ナス病害）
export let partEggplantDd = [
  '葉（表）',
  '葉（裏）',
  // '花',
  '果実',
  // '茎',
  // '茎頂部',
  // '茎断面',
  // '地際部',
  // '全体',
];
// 部位（ナス虫害）
export let partEggplantPest = [
  // '葉（表）',
  // '葉（裏）',
  '葉',
  // '花',
  '果実',
  // '茎',
  // '茎頂部',
  // '茎断面',
  // '地際部',
  // '全体',
];
// 部位（イチゴ病害）
export let partStrawberryDd = [
  '葉（表）',
  '葉（裏）',
  // '葉柄',
  // '花',
  '果実',
  // '果柄',
  // 'ランナー',
  // '茎頂部',
  // 'クラウン',
  // '全体',
];
// 部位（イチゴ虫害）
export let partStrawberryPest = [
  // '葉（表）',
  // '葉（裏）',
  '葉',
  // '葉柄',
  '花',
  '果実',
  // '果柄',
  // 'ランナー',
  // '茎頂部',
  // 'クラウン',
  // '全体',
];
// 部位（ブドウ）
export let partGrapes = [
  '葉（表）',
  '葉（裏）',
  '主幹部（苗木）',
  '主幹部（成木）',
  '枝',
  '果実（幼果期）',
  '果実（成熟期～収穫期）',
];
// 部位（モモ）
export let partPeach = [
  '葉（表）',
  '葉（裏）',
  '主幹部（苗木）',
  '主幹部（成木）',
  '枝',
  '果実（幼果期）',
  '果実（成熟期～収穫期）',
];
// 部位（タマネギ）
export let partOnion = [
  '葉（表）',
  '全体',
];
// 部位（ジャガイモ）
export let partPotato = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '全体',
];
// 部位（ダイズ（エダマメ））
export let partGreenSoybeans = [
  '葉（表）',
  '葉（裏）',
  '葉茎',
  '莢',
  '全体',
];
// 部位（ピーマン）
export let partGreenPepper = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '果実',
  '全体',
];
// 部位（カボチャ）
export let partPumpkin = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '果実',
  '全体',
];
// 部位（キク）
export let partKiku = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '花',
  '全体',
];
// 部位（ラナンキュラス）
export let partRanunculus = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '花',
  '全体',
];
// 部位（トルコギキョウ）
export let partLisianthus = [
  '葉（表）',
  '葉（裏）',
  '茎',
  '花',
  '全体',
];

// 正解（病害,トマト）
export let answerDisaseTomato = [
  'うどんこ病',
  '葉かび病',
  '灰色かび病',
  'すすかび病',
  '青枯病',
  'かいよう病',
  '黄化葉巻病',
  '褐色輪紋病',
  '疫病',
  '半身萎凋病',
  '萎凋病',
  'モザイク病',
  '斑点細菌病',
  '輪紋病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,キュウリ）
export let answerDisaseCucumber = [
  'うどんこ病',
  'べと病',
  '褐斑病',
  'つる枯病',
  'モザイク病',
  '緑斑モザイク病',
  '黄化えそ病',
  '退緑黄化病',
  '灰色かび病',
  '黒星病',
  '疫病',
  '斑点細菌病',
  '炭疽病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ナス）
export let answerDisaseEggplant = [
  'うどんこ病',
  '灰色かび病',
  'すすかび病',
  '青枯病',
  '褐色円星病',
  '半身萎凋病',
  '褐色腐敗病',
  '褐紋病',
  '菌核病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,イチゴ）
export let answerDisaseStrawberry = [
  'うどんこ病',
  '萎黄病',
  '炭疽（萎凋）',
  '灰色かび病',
  'モザイク病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ブドウ）
export let answerDisaseGrapes = [
  'べと病',
  '褐斑病',
  '根頭がんしゅ病',
  '晩腐病',
  'リーフロール病',
  'うどんこ病',
  'さび病',
  'つる割病',
  'ペスタロチアつる枯病',
  '灰色かび病',
  '黒とう病',
  '白腐病',
  '生理障害（マグネシウム欠乏症）',
  '奇形葉（生理障害等）',
  'その他の生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,モモ）
export let answerDisasePeach = [
  'せん孔細菌病',
  '縮葉病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,タマネギ）
export let answerDisaseOnion = [
  'べと病',
  '白色疫病',
  'さび病',
  '乾腐病',
  'ボトリチス葉枯病',
  '葉枯症状',
  '灰色腐敗病',
  '細菌性腐敗',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ジャガイモ）
export let answerDisasePotato = [
  'モザイク病',
  '黒あし病',
  '軟腐病',
  '疫病',
  '夏疫病',
  'そうか病',
  '黒あざ病',
  '葉巻病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ダイズ（エダマメ））
export let answerDisaseGreenSoybeans = [
  'べと病',
  '褐紋病',
  '黒根腐病',
  '茎疫病',
  'モザイク病',
  '細菌性病害',
  'さび病',
  'わい化病',
  '菌核病',
  '黒とう病',
  '紫斑病',
  '炭そ病',
  '白絹病',
  '立枯性病害',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ピーマン）
export let answerDisaseGreenPepper = [
  'モザイク病',
  'えそ病',
  'うどんこ病',
  '斑点病',
  '斑点細菌病',
  '黒枯病',
  '疫病',
  '青枯病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,カボチャ）
export let answerDisasePumpkin = [
  'モザイク病',
  'うどんこ病',
  '疫病',
  '褐斑細菌病',
  '白斑病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,キク）
export let answerDisaseKiku = [
  'えそ病',
  '茎えそ病',
  '白さび病',
  'キク矮化病',
  'キク退緑斑紋病',
  'べと病',
  '黒斑病、褐斑病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,ラナンキュラス）
export let answerDisaseRanunculus = [
  'モザイク病',
  '灰色かび病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（病害,トルコギキョウ）
export let answerDisaseLisianthus = [
  'えそ萎縮病',
  'えそ斑紋病',
  'えそ輪紋病',
  '黄化えそ病',
  'えそ病',
  'えそモザイク病',
  '立枯病',
  '根腐病',
  '茎腐病',
  '灰色かび病',
  '炭疽病',
  '黒斑病、褐斑病',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];

// 正解（虫害,トマト）
export let answerPestTomato = [
  'コナジラミ類',
  'ハダニ類',
  'アブラムシ類',
  'アザミウマ類',
  'シロイチモジヨトウ',
  'タバコガ類',
  'ハスモンヨトウ',
  'ハモグリバエ類',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,キュウリ）
export let answerPestCucumber = [
  'アザミウマ類',
  'コナジラミ類',
  'ハダニ類',
  'チャノホコリダニ',
  'アブラムシ類',
  'ウリハムシ',
  'タバコガ類',
  'ハスモンヨトウ',
  'ハモグリバエ類',
  'ワタヘリクロノメイガ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ナス）
export let answerPestEggplant = [
  'アザミウマ類',
  'ハダニ類',
  'チャノホコリダニ',
  'アブラムシ類',
  'ハスモンヨトウ',
  'ハモグリバエ類',
  'ニジュウヤホシテントウ類',
  'コナジラミ類',
  'ヨトウガ',
  'タバコガ類',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,イチゴ）
export let answerPestStrawberry = [
  'コナジラミ類',
  'アブラムシ類',
  'ハダニ類',
  'ハスモンヨトウ',
  'アザミウマ類',
  'シロイチモジヨトウ',
  'タバコガ類',
  'ヨトウガ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ブドウ）
export let answerPestGrapes = [
  'ハダニ類',
  'ハモグリダニ',
  'アザミウマ類',
  'アブラムシ類',
  'カイガラムシ類',
  'フタテンヒメヨコバイ',
  'カメムシ類',
  'コガネムシ類',
  'ハマキムシ類',
  'ブドウトラカミキリ',
  'ハスモンヨトウ',
  'モンキクロノメイガ',
  '生理障害（マグネシウム欠乏症）',
  '奇形葉（生理障害等）',
  'その他の生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,モモ）
export let answerPestPeach = [
  'ハダニ類',
  'カンザワハダニ',
  'ナミハダニ',
  'アブラムシ類',
  'カイガラムシ類',
  'カメムシ類',
  'モモハモグリガ',
  'コスカシバ',
  'モモシンクイガ',
  'ナシヒメシンクイ',
  'ハマキムシ類',
  'モモノゴマダラノメイガ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,タマネギ）
export let answerPestOnion = [
  'アブラムシ類',
  'アザミウマ類',
  'ネギハモグリバエ',
  'タマネギバエ',
  'ネギコガ',
  'シロイチモンジヨトウ',
  'ヨトウガ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ジャガイモ）
export let answerPestPotato = [
  'オオニジュウヤホシテントウ',
  'ナストビハムシ',
  'アブラムシ類',
  'ヤガ類',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ダイズ（エダマメ））
export let answerPestGreenSoybeans = [
  'ハダニ類',
  'アブラムシ類',
  'ジャガイモヒゲナガアブラムシ',
  '吸実性カメムシ類',
  'ダイズサヤタマバエ',
  'ダイズクロハモグリバエ',
  'タネバエ',
  'タバコガ類',
  'ヒメサヤムシ類',
  'マメシンクイガ',
  'ウコンノメイガ',
  'シロイチモンジヨトウ',
  'シロイチモンジマダラメイガ',
  'ハスモンヨトウ',
  'コガネムシ類',
  'フタスジヒメハムシ',
  'マメハンミョウ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ピーマン）
export let answerPestGreenPepper = [
  'ハダニ類',
  'アブラムシ類',
  'タバコガ類',
  'アザミウマ類',
  'ヒラズハナアザミウマ',
  'ミカンキイロアザミウマ',
  'コナジラミ類',
  'シロイチモンジヨトウ',
  'ハスモンヨトウ',
  'ヨトウガ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,カボチャ）
export let answerPestPumpkin = [
  'アブラムシ類',
  'コナジラミ類',
  'ウリハムシ',
  'ハモグリバエ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,キク）
export let answerPestKiku = [
  'ハダニ類',
  'キクモンサビダニ',
  'アザミウマ類',
  'ミナミキイロアザミウマ',
  'ミカンキイロアザミウマ',
  'アブラムシ類',
  'カスミカメムシ類',
  'ハモグリバエ類',
  'タバコガ類',
  'シロイチモンジヨトウ',
  'ハスモンヨトウ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,ラナンキュラス）
export let answerPestRanunculus = [
  'ハモグリバエ',
  '生理障害等（非病虫害）',
  'その他病虫害',
  '健全',
];
// 正解（虫害,トルコギキョウ）
export let answerPestLisianthus = [
  '健全',
];

export const setMasterInfo = (masterInfo: any) => {
  // 部位
  partTomatoDd = masterInfo.part.tomatoDd;
  partTomatoPest = masterInfo.part.tomatoPest;
  partCucumberDd = masterInfo.part.cucumberDd;
  partCucumberPest = masterInfo.part.cucumberPest;
  partEggplantDd = masterInfo.part.eggplantDd;
  partEggplantPest = masterInfo.part.eggplantPest;
  partStrawberryDd = masterInfo.part.strawberryDd;
  partStrawberryPest = masterInfo.part.strawberryPest;
  partGrapes = masterInfo.part.grapes;
  partPeach = masterInfo.part.peach;
  partOnion = masterInfo.part.onion;
  partPotato = masterInfo.part.potato;
  partGreenSoybeans = masterInfo.part.greenSoybeans;
  partGreenPepper = masterInfo.part.greenPepper;
  partPumpkin = masterInfo.part.pumpkin;
  partKiku = masterInfo.part.kiku;
  partRanunculus = masterInfo.part.ranunculus;
  partLisianthus = masterInfo.part.lisianthus;

  // 正解（病害）
  answerDisaseTomato = masterInfo.answer.disease.tomato;
  answerDisaseCucumber = masterInfo.answer.disease.cucumber;
  answerDisaseEggplant = masterInfo.answer.disease.eggplant;
  answerDisaseStrawberry = masterInfo.answer.disease.strawberry;
  answerDisaseGrapes = masterInfo.answer.disease.grapes;
  answerDisasePeach = masterInfo.answer.disease.peach;
  answerDisaseOnion = masterInfo.answer.disease.onion;
  answerDisasePotato = masterInfo.answer.disease.potato;
  answerDisaseGreenSoybeans = masterInfo.answer.disease.greenSoybeans;
  answerDisaseGreenPepper = masterInfo.answer.disease.greenPepper;
  answerDisasePumpkin = masterInfo.answer.disease.pumpkin;
  answerDisaseKiku = masterInfo.answer.disease.kiku;
  answerDisaseRanunculus = masterInfo.answer.disease.ranunculus;
  answerDisaseLisianthus = masterInfo.answer.disease.lisianthus;

  // 正解（虫害）
  answerPestTomato = masterInfo.answer.pest.tomato;
  answerPestCucumber = masterInfo.answer.pest.cucumber;
  answerPestEggplant = masterInfo.answer.pest.eggplant;
  answerPestStrawberry = masterInfo.answer.pest.strawberry;
  answerPestGrapes = masterInfo.answer.pest.grapes;
  answerPestPeach = masterInfo.answer.pest.peach;
  answerPestOnion = masterInfo.answer.pest.onion;
  answerPestPotato = masterInfo.answer.pest.potato;
  answerPestGreenSoybeans = masterInfo.answer.pest.greenSoybeans;
  answerPestGreenPepper = masterInfo.answer.pest.greenPepper;
  answerPestPumpkin = masterInfo.answer.pest.pumpkin;
  answerPestKiku = masterInfo.answer.pest.kiku;
  answerPestRanunculus = masterInfo.answer.pest.ranunculus;
  answerPestLisianthus = masterInfo.answer.pest.lisianthus;
}

// モード変更パスワード
export const changeModePasword = 'teacher';

// 診断履歴で1度に読み出す件数
export const historyPageLimit = 10;
// 診断履歴用のサムネサイズ
export const thumbnailWidth = 300;
// 診断履歴用のサムネ圧縮率
export const thumbnailCompress = 0.7;

// スタイル定義
//export const defaultColor = '#3b5fff';
export const defaultColor = '#1fa193';
export const styles = StyleSheet.create({
  photo: {
    flex: 1,
  },
  fontSizeLL: {
    fontSize: 26,
  },
  fontSizeL: {
    fontSize: 20,
  },
  fontSizeM: {
    fontSize: 18,
  },
  fontSizeS: {
    fontSize: 12,
  },
  loading: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'white',
    opacity: 0.6
  },
});

import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, View, SafeAreaView, Platform, TouchableHighlight, Linking, useWindowDimensions } from 'react-native';
import * as ApiControl from './ApiControl';
import * as CmnUtil from './CmnUtil';
import { List } from 'react-native-paper';
import IconImages from './IconImages';
import Swiper from 'react-native-swiper/src';
import Carousel from 'react-material-ui-carousel';
import ImageModal from 'react-native-image-modal';
import { NonRetryableError } from '@aws-amplify/core';


let Pesticide_detailsSuspension = false;
const ScreenDetail = ({ route, navigation }: { route: any, navigation: any }) => {
  
  const [photos, setPhotos] = useState<{ key: string; picture: string; licensee: string; icensee_url: string; caption: string}[]>([]);

  const [pesticide, setPesticide] = useState<{ key: string; application_num: string; pesticide_name: string; company_name: string}[]>([]);

  const [species, setSpecies] = useState<{ key: string; species: string}[]>([]);
  const [host_range, setHost_range] = useState<{ key: string; host_range: string}[]>([]);
  const [citations, setCitations] = useState<{ key: number; citations: string}[]>([]);

  const [isVisible, setVisibility] = useState(false);

  const updateVisibility = () => {
    setVisibility(!isVisible);
  };

  const width = useWindowDimensions().width;
  const tmp = route.params.dict_results.ppd_dict[0];

  useEffect(() => {
    const tmp_pictures_result = [];
    const tmp_Pictures = route.params.pictures_result.photos
    if(tmp_Pictures != null){
      for (let i = 0; i < tmp_Pictures.length; i++) {
        const tmp = tmp_Pictures[i];
        if(tmp.caption == "None"){
          tmp.caption = ""
        }
        if(tmp.licensee == "None"){
          tmp.licensee = ""
        }
        tmp_pictures_result[i] = { key: i.toString(), picture: tmp.picture, licensee: tmp.licensee, icensee_url: tmp.icensee_url, caption: tmp.caption};
      }
    }else{
      tmp_pictures_result[0] = { key: "0".toString(), picture: IconImages.noImage, licensee: "", icensee_url: "" , caption: "" };
    }
    setPhotos(tmp_pictures_result);
  }, []);

  useEffect(() => {
    const tmp_pesticide_result = [];
    for (let i = 0; i < route.params.pesticide_result.list.length; i++) {
      const tmp = route.params.pesticide_result.list[i]
      tmp_pesticide_result[i] = { key: i.toString(), application_num: tmp.Application_登録番号, pesticide_name: tmp.Basic_農薬の名称, company_name: tmp.Basic_農薬登録を有している者の氏名又は名称};
    }
    setPesticide(tmp_pesticide_result);
  }, []);

  useEffect(() => {
    const tmp_species = [];
    const tmp_host_range = [];
    const tmp_citations = [];
    const tmp = route.params.dict_results.ppd_dict[0]
    for (let i = 0; i < tmp.species.length; i++) {   
      tmp_species[i] = { key: i.toString(), species: tmp.species[i]};
    }
    for (let i = 0; i < tmp.host_range.length; i++) {   
      tmp_host_range[i] = { key: i.toString(), host_range: tmp.host_range[i]};
    }
    for (let i = 0; i < tmp.citations.length; i++) {   
      tmp_citations[i] = { key: i + 1, citations: tmp.citations[i]};
    }
    setSpecies(tmp_species);
    setHost_range(tmp_host_range);
    setCitations(tmp_citations);
  }, []);

  const renderPagination = (index: number, total: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => {
    return (
      <View>
        <Text style={styles.pagination}>
          <Text>{index + 1}</Text>/{total}
        </Text>
      </View>
    )
  }

  const carouselLoop = () => {
    if (Platform.OS === 'web'){
      return(
        <View style={styles.container}>
          <Carousel
          NextIcon={<Text style={styles.carouselButton}>次へ</Text>}
          PrevIcon={<Text style={styles.carouselButton}>前へ</Text>}
          navButtonsAlwaysVisible={true}
          autoPlay={false}
          swipe={false}
          animation='slide'
          navButtonsProps={{ 
            style: {
              backgroundColor: '#d6d7d7',
              borderRadius: 5,
            }
          }} 
          navButtonsWrapperProps={{
            style: {
              marginTop: '180px',
            }
          }}>
            {photos.map((item) => {
              return (
                <View key={item.key}>
                  <TouchableHighlight>
                  <ImageModal
                    imageBackgroundColor="#000000"
                    style={{ height:350,width:width}} resizeMode="contain" source={{uri: "data:image/jpeg;base64," + item.picture}}
                    />
                  </TouchableHighlight>
                  <TouchableHighlight
                    onPress={() => item.icensee_url && Linking.openURL(item.icensee_url)}
                    style={{ bottom: 59 ,end: 80}}>
                    <View>
                    {item.icensee_url == "None" || item.icensee_url == "" ? <Text style={styles.nolicense}>出典『{item.licensee}』</Text> : <Text style={styles.license}>出典『{item.licensee}』</Text>}
                    </View>
                  </TouchableHighlight>
                  <View style={{ bottom: 58 }}>
                    <Text style={styles.webCaption}>{item.caption}</Text>
                  </View>
                </View>
              );
            })}
          </Carousel>
        </View>
      )
    } else {
      return(
        <View style={styles.container}>
          <Swiper
            // renderPagination={renderPagination}
            height={400}
            showsButtons={true}
            nextButton={<Text style={styles.carouselButton}>次へ</Text>}
            prevButton={<Text style={styles.carouselButton}>前へ</Text>}
            buttonWrapperStyle={styles.buttonWrapper}>
            {photos.map((item) => {
              return (
                <View key={item.key}>
                  <TouchableHighlight>
                  <ImageModal
                    imageBackgroundColor="#000000"
                    style={{ height:350,width:width}} resizeMode="contain" source={{uri: "data:image/jpeg;base64," + item.picture}}
                    />
                  </TouchableHighlight>
                  <TouchableHighlight
                    onPress={() => item.icensee_url && Linking.openURL(item.icensee_url)}
                    style={{ bottom: 55 }}>
                    <View>
                    {item.icensee_url == "None" || item.icensee_url == "" ? <Text style={styles.nolicense}>出典『{item.licensee}』</Text> : <Text style={styles.license}>出典『{item.licensee}』</Text>}
                    </View>
                  </TouchableHighlight>
                  <Text style={ styles.caption}>{item.caption}</Text>
                </View>
              );
            })}
          </Swiper>
        </View>
      )
    }
  }
    
  const accodionListMain = () => {
    return(
      <View>
          <List.Section>
            <List.Accordion
                style={styles.acoodionMain}
                title='生物種'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.pest_disease}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='病虫害の説明'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.description}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='発生要因、伝染経路'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.route_of_infection}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='防除法'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.control_methods}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='さらに詳しい情報はこちら'
                onPress={updateVisibility}>
            </List.Accordion>
          </List.Section>
        </View>
    );
  }

  const accodionListSub = () =>{
    return(
      <View>
          <List.Section>
            <List.Accordion
                style={styles.acoodionMain}
                title='対応する生物名・病原体'>
                  {species.map((item) => {
                    return (
                      <List.Item
                          key={item.key}
                          titleNumberOfLines={0}
                          style={{ width: '100%' }}
                          title={item.species}/>
                          );
                  })}
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='よく間違えられる病虫害'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.confusable_pest_diseases}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='宿主範囲'>
                {host_range.map((item) => {
                    return (
                      <List.Item
                          key={item.key}
                          titleNumberOfLines={0}
                          style={{ width: '100%' }}
                          title={item.host_range}/>
                          );
                  })}
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='作物の加害ステージ'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.crop_susceptible_stage}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='発生時期'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.occurrence_period_greenhouse}/>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.occurrence_period_outdoor}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='分布域'>
                <List.Item
                    titleNumberOfLines={0}
                    style={{ width: '100%' }}
                    title={tmp.distribution}/>
            </List.Accordion>
            <List.Accordion
                style={styles.acoodionMain}
                title='出典'>
                {citations.map((item) => {
                    return (
                      <List.Item
                          key={item.key}
                          titleNumberOfLines={0}
                          style={{ width: '100%' }}
                          title={"[" + item.key + "]" + item.citations}/>
                          );
                  })}
            </List.Accordion>
        </List.Section>
      </View>
    );
  }

  const pesticideList = () => {
    return(
      <View style={styles.container}>
          {pesticide.map((item) => {
            return (
              <View key={item.key} style={styles.pesticideList}>
                <Text style={styles.pesticide}>{item.pesticide_name}</Text>
                <Text style={{width: '100%'}}>{item.company_name}</Text>
                <TouchableHighlight style={styles.button} activeOpacity={0.6} underlayColor='pink' onPress={() => {pesticide_details(route.params.name, route.params.plant, item.application_num, item.pesticide_name)}}>
                  <Text style={styles.detail}>詳細はこちら</Text> 
                </TouchableHighlight>
              </View>
            );
          })}
      </View>
    );
  }

  const pesticide_details = async (name: string, plant: string, num: string, pesticide_name: string) => {
    
    // ローディング表示
    CmnUtil.startLoading();
    Pesticide_detailsSuspension = false;

    // 診断実行
    ApiControl.pesticide_details(name, plant, num, async (response: any) => {
      // 結果画面へ遷移
      if (response.details.length == 0) {
        CmnUtil.endLoading();
        CmnUtil.appAlert('詳細な情報がありません。他の項目をお試しください。');
      }else{
        CmnUtil.endLoading();
        navigation.navigate('PestcideDetails', { result: response,  pesticide_name: pesticide_name });
      }
    },
      () => {
        CmnUtil.endLoading();
        CmnUtil.appAlert('通信に失敗しました。通信環境を確認の上、再度お試しください。');
      });
  }
  
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>

      <View style={styles.container}>
        <View style={{ borderBottomWidth: 2, borderBottomColor: "black" }}>
          <Text style={{ fontSize: 24, borderBottomColor: '#4a4a4a' }}>{route.params.name}（{route.params.plant}）</Text>
        </View>
        <Text style={{ fontSize: 16, width: '100%' }}>{tmp.abstract}</Text>
      </View>

        <View>
          {carouselLoop()}
        </View>
 
        <View>
          {accodionListMain()}
        </View>

        <View>
          {isVisible ? accodionListSub() : null}
        </View>

        <View>
         <Text style={ styles.pesticideInfo }>この病虫害に有効な農薬を紹介します。</Text>
        </View>

        <View>
          {pesticideList()}
        </View>

      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  scrollView: {
    flex: 1,
  },
  acoodionMain: {
    borderBottomWidth: 1,
    borderBottomColor: "#DCDCDC",
    width: '100%',
  },
  photo: {
    height: 350,
    backgroundColor: 'black',
  },
  license: {
    textAlign: 'right',
    color: 'aqua',
    textDecorationLine:'underline',
  },
  nolicense: {
    textAlign: 'right',
    color: 'white',
  },
  caption: {
    textAlign: 'right',
    color: 'white',
    bottom: 53,
  },
  webCaption: {
    textAlign: 'right',
    color: 'white',
    marginRight: 80
  },
  pesticideList: {
    margin: 5,
    flexDirection:'column',
     flexWrap:'wrap',
  },
  pesticide: {
    fontSize: 20,
    fontWeight: 'bold', 
    width: '100%',
  },
  pesticideInfo: {
    backgroundColor: '#B3B2B2',
    fontSize: 16,
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#E34D4D',
    width: 150,
    borderRadius: 10,
  },
  detail: {
    alignSelf: 'center',
    marginTop: 5,
    marginBottom: 5,
    color: 'white',
  },
  pagination: {
    color: 'black',
    textAlign: 'center', 
  },
  carouselButton: {
    backgroundColor: '#d6d7d7',
    width: '100%',
    height: '25%',
    paddingStart: 15,
    paddingEnd: 15,
    paddingTop: 2,
    textAlign: 'center',
    borderRadius: 5,
  },
  buttonWrapper: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    position: 'absolute',
    top: '93%',
    start: 0,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
});

export default ScreenDetail;

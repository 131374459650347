import React, { useState, useEffect, useRef, useMemo } from 'react'
import { StyleSheet, Platform, ActivityIndicator, Text, Image, TouchableOpacity, View, SafeAreaView, FlatList, Alert } from 'react-native'
import { HeaderBackButton } from '@react-navigation/stack';
import * as AppConst from './AppConst';
import * as ApiControl from './ApiControl';
import IconImages from './IconImages';
import * as DbControl from './DbControl';
import * as CmnUtil from './CmnUtil';
import * as SettingData from './SettingData';
import DateTimePicker from '@react-native-community/datetimepicker';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';

const ScreenHistory = ({ navigation }: { navigation: any }) => {
  const flatListRef = useRef<any>(undefined);

  const [page, setPage] = useState<number | undefined>(undefined);
  const [clientData, setClientData] = useState([]);
  const [readData, setReadData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      CmnUtil.startLoading();
    } else {
      CmnUtil.endLoading();
    }
  }, [loading]);
  const [loadmore, setLoadmore] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [mode, setMode] = useState('search');
  const [deleteData, setDeleteData] = useState<number[]>([]);

  // Wifiメッセージ表示
  const [showFiller, setShowFiller] = useState(false);

  // フィルタ表示
  const [fillter, setFillter] = useState({ category: '指定なし', plant: '指定なし', from: '指定なし', to: '指定なし', estimated: '指定なし' });
  const [tmpFillter, setTmpFillter] = useState({ category: '指定なし', plant: '指定なし', from: '指定なし', to: '指定なし', estimated: '指定なし' });
  const [datepicker, setDatepicker] = useState<undefined | string>(undefined);
  // モーダル選択内容
  const [selectModal, setSelectModal] = useState<undefined | string>(undefined);
  const [modalItem, setModalItem] = useState<string[]>([]);

  let tmpIosDate = CmnUtil.formatDate(new Date());

  useEffect(() => {
    // ヘッダのボタン
    if (mode === 'delete') {
      navigation.setOptions({
        headerLeft: (props: any) => (
          <HeaderBackButton
            {...props}
            onPress={resetDeleteList}>
          </HeaderBackButton>
        ),
        headerRight: () => (
          <TouchableOpacity style={{ marginRight: 20 }}
            onPress={() => deleteConfirm(deleteData)}>
            <Image style={{ width: 30, height: 30, marginLeft: 10 }} resizeMode='cover' source={IconImages.icDelete} />
          </TouchableOpacity>
        ),
      });
    } else {
      navigation.setOptions({
        headerLeft: (props: any) => (
          <HeaderBackButton
            {...props}
            onPress={() => navigation.goBack()}>
          </HeaderBackButton>
        ),
        headerRight: () => (
          <TouchableOpacity style={{ marginRight: 20 }}
            onPress={() => { onPressFillter() }}>
            <Image style={{ width: 30, height: 30, marginLeft: 10 }} resizeMode='cover' source={IconImages.icFillter} />
          </TouchableOpacity>
        ),
      });
    }
  }, [mode, deleteData, showFiller]);

  // DB読出処理
  const getResultList = (page: number) => {
    DbControl.getDianosisResultList(page, AppConst.historyPageLimit, fillter, (results: any) => {
      setReadData(results);
    },
      () => {
        setReadData([]);
      }
    );
  }

  // DB読出後処理
  useEffect(() => {
    if (readData.length > 0) {
      setRefresh(false);
      setClientData([...clientData, ...readData]);
      setLoadmore(readData.length == AppConst.historyPageLimit ? true : false);
    } else {
      setLoadmore(false);
    }
    setLoading(false);
  }, [readData]);

  // DB読出後処理
  useEffect(() => {
    if (page == undefined) {
      setPage(1);
    } else {
      if (readData.length == AppConst.historyPageLimit || page == 1) {
        setLoading(true);
        getResultList(page);
      }
    }
  }, [page]);

  // 追加読出処理
  const handleLoadMore = () => {
    if (loadmore && !loading) {
      setPage(page! + 1);
    }
  }

  // 初期化時処理
  const onRefresh = () => {
    setClientData([]);
    setPage(undefined);
  }

  // keyExtractor
  const keyExtractor = (item: any, index: number) => item.result_id.toString();

  // 診断結果画面に遷移
  const navigateResult = async (item: any) => {

    // ローディング表示
    CmnUtil.endLoading();
    
    DbControl.getDianosisResult(item.result_id,
      (result: any, group: any, candidate: any, pesticide: any, photo: any) => {

        // iOSでアップデート時にパスが変わるので、診断時のパスから現在のパスに変更
        let path = result[0].data;
        const words = result[0].data.split('/');
        if (words.length >= 2) {
          path = FileSystem.documentDirectory + '/' + words[words.length - 2] + '/' + words[words.length - 1];
        }

        const info = {
          id: result[0].result_id,
          filename: result[0].filename,
          data: path,
          lat: result[0].lat,
          lot: result[0].lot,
          answer: result[0].answer,
          category: result[0].category,
          plant: result[0].plant,
          part: result[0].part,
          photoW: result[0].photoW,
          photoH: result[0].photoH,
          photo_date: result[0].photo_date,
        }

        const tmp_result = [];
        for (let i = 0; i < group.length; i++) {
          const elementI = group[i];

          const tmp_bbox = [];
          if (elementI.point_lt_x !== undefined) {
            tmp_bbox.push(elementI.point_lt_x);
            tmp_bbox.push(elementI.point_lt_y);
            tmp_bbox.push(elementI.point_rb_x);
            tmp_bbox.push(elementI.point_rb_y);
          }

          const tmp_candidate = [];
          for (let j = 0; j < candidate.length; j++) {
            const elementJ = candidate[j];
            if (elementI.group_id !== elementJ.group_id) {
              continue;
            }

            const tmp_pesticide = [];
            for (let k = 0; k < pesticide.length; k++) {
              const elementK = pesticide[k];
              if (elementI.group_id !== elementK.group_id
                || elementJ.rank !== elementK.rank) {
                continue;
              }
              tmp_pesticide.push({
                name: elementK.name,
                company: elementK.company,
                url: elementK.url,
              });
            }

            const tmp_photo = [];
            for (let k = 0; k < photo.length; k++) {
              const elementK = photo[k];
              if (elementI.group_id !== elementK.group_id
                || elementJ.rank !== elementK.rank) {
                continue;
              }
              tmp_photo.push({
                thumbnail: elementK.thumbnail,
                original: elementK.original,
                license: {
                  licensee: elementK.licensee,
                  licensee_url: elementK.licensee_url,
                },
              });
            }

            tmp_candidate.push({
              estimated: elementJ.estimated,
              probability: elementJ.probability,
              abstract: elementJ.abstract,
              pesticide: tmp_pesticide,
              photos: tmp_photo[0].thumbnail,
            });
          }

          tmp_result.push({
            bbox: tmp_bbox.length > 0 ? tmp_bbox : undefined,
            candidates: tmp_candidate.sort((first: any, second: any) => second.probability - first.probability),
          });
        }

        // 診断結果画面へ
        navigation.navigate('Result', { info: info, results: tmp_result });
      },
      () => { }
    )
  };

  // 履歴削除
  const deleteConfirm = (list: number[]) => {
    Alert.alert(
      '診断結果を' + list.length + '件削除しますか？',
      undefined,
      [
        { text: 'いいえ', },
        { text: 'はい', onPress: () => deleteResult(list) }
      ],
      { cancelable: false }
    );
  }

  // フィルタ
  const onPressFillter = () => {
    if (!showFiller) {
      setTmpFillter({ ...fillter });
    }
    setShowFiller(!showFiller);
    //setFillter({ category: '虫害', plant: 'トマト', from: '2021/01/01', to: '2021/01/07', estimated: 'アザミウマ' });
    //onRefresh();
  }

  // 履歴削除
  const deleteResult = (list: number[]) => {
    setLoading(true);
    DbControl.deleteDianosisResult(list, () => {
      resetDeleteList();
      CmnUtil.appMsg('診断結果を削除しました。');
      onRefresh();
    },
      () => { }
    );
  }

  // 削除候補リセット
  const resetDeleteList = () => {
    setMode('search');
    setDeleteData([]);
  }

  // 履歴クリック
  const onPressHistory = (item: any) => {
    if (mode === 'search') {
      navigateResult(item);
    } else {
      onLongPressHistory(item);
    }
  }

  // 履歴長押し
  const onLongPressHistory = (item: any) => {
    setMode('delete');

    // 削除配列になければ追加、あれば削除
    const index = deleteData.indexOf(item.result_id);
    if (index < 0) {
      setDeleteData([...deleteData, item.result_id]);
    } else {
      deleteData.splice(index, 1);
      setDeleteData([...deleteData]);
      // 選択数が0の場合はキャンセルとする
      if (deleteData.length == 0) {
        setMode('search');
      }
    }
  }

  // フィルタ表示
  const FillterView = () => {
    return (
      <View style={styles.fillter}>
        <View style={{ backgroundColor: 'white', width: '90%', borderRadius: 5, padding: 20 }}>
          <Text style={AppConst.styles.fontSizeL}>絞り込み</Text>

          <Text style={styles.itemText}>診断対象</Text>
          <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
            onPress={() => { setModalItem(['指定なし', '病害', '虫害']); setSelectModal('category'); }}>
            <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{tmpFillter.category}</Text>
            <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
          </TouchableOpacity>

          <Text style={styles.itemText}>作物</Text>
          <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
            onPress={() => { setModalItem(['指定なし', 'トマト', 'イチゴ', 'キュウリ', 'ナス', 'ブドウ', 'モモ', 'タマネギ', 'ジャガイモ', 'ダイズ（エダマメ）', 'ピーマン', 'カボチャ', 'キク', 'ラナンキュラス', 'トルコギキョウ']); setSelectModal('plant'); }}>
            <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{tmpFillter.plant}</Text>
            <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
          </TouchableOpacity>

          <Text style={styles.itemText}>撮影日時(開始)</Text>
          <View style={{ flexDirection: 'row', minHeight: 30 }}>
            <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', width: '70%' }}
              onPress={() => { setDatepicker('from'); }}>
              <Text style={[AppConst.styles.fontSizeLL]}>{tmpFillter.from}</Text>
              <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
            </TouchableOpacity>
            <TouchableOpacity style={{ position: 'absolute', right: 10 }} onPress={() => { setTmpFillter({ ...tmpFillter, from: '指定なし' }); }} >
              <Text style={{ fontSize: 30 }}>×</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.itemText}>撮影日時(終了)</Text>
          <View style={{ flexDirection: 'row', minHeight: 30 }}>
            <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', width: '70%' }}
              onPress={() => { setDatepicker('to'); }}>
              <Text style={[AppConst.styles.fontSizeLL]}>{tmpFillter.to}</Text>
              <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
            </TouchableOpacity>
            <TouchableOpacity style={{ position: 'absolute', right: 10 }} onPress={() => { setTmpFillter({ ...tmpFillter, to: '指定なし' }); }} >
              <Text style={{ fontSize: 30 }}>×</Text>
            </TouchableOpacity>
          </View>

          {/* android用日付選択 */}
          {Platform.OS == 'android' && datepicker && <DateTimePicker
            testID="dateTimePicker"
            value={datepicker == 'from' ? CmnUtil.formatToDate(tmpFillter.from) : CmnUtil.formatToDate(tmpFillter.to)}
            mode='date'
            is24Hour={true}
            display="default"
            locale='ja'
            // @ts-ignore
            onChange={(event, date) => {
              setDatepicker(undefined);
              if (event.type == 'set') {
                if (datepicker == 'from') {
                  setTmpFillter({ ...tmpFillter, from: date == undefined ? '指定なし' : CmnUtil.formatDate(date) });
                } else {
                  setTmpFillter({ ...tmpFillter, to: date == undefined ? '指定なし' : CmnUtil.formatDate(date) });
                }
              }
            }}
          />}

          <Text style={styles.itemText}>診断結果</Text>
          <TouchableOpacity style={{ borderBottomWidth: 1, flexDirection: 'row', minHeight: 30 }}
            onPress={() => { setModalItem(getEstimatedList(tmpFillter.category, tmpFillter.plant)); setSelectModal('estimated'); }}>
            <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{tmpFillter.estimated}</Text>
            <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
          </TouchableOpacity>

          <View style={{ flexDirection: 'row', paddingTop: 20 }}>
            <TouchableOpacity style={[styles.selectButton, { backgroundColor: '#4a4a4a' }]} onPress={() => { setShowFiller(false) }}>
              <View style={styles.center}>
                <Text style={styles.operationBackNextButton}>キャンセル</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={() => {
              setFillter({ ...tmpFillter });
              setShowFiller(false);
              onRefresh();
            }}>
              <View style={styles.center}>
                <Text style={styles.operationBackNextButton}>OK</Text>
              </View>
            </TouchableOpacity>
          </View>

        </View>

        {/* 選択ダイアログ */}
        {selectModal && <TouchableOpacity style={styles.modal} activeOpacity={1.0} onPress={() => { setSelectModal(undefined) }}>
          <FlatList
            style={{ width: '90%', height: '90%', marginTop: 150, marginBottom: 150, backgroundColor: 'white', borderRadius: 5 }}
            data={modalItem}
            renderItem={renderItemFillter}
            keyExtractor={(item: any, index: number) => index.toString()}
          />
        </TouchableOpacity>}

        {/* iOS用日付選択 */}
        {Platform.OS == 'ios' && datepicker && <TouchableOpacity style={styles.modal} activeOpacity={1.0} onPress={() => { setDatepicker(undefined) }}>
          <View style={{ backgroundColor: 'white', width: '80%' }} >
            <DateTimePicker
              value={datepicker == 'from' ? CmnUtil.formatToDate(tmpFillter.from) : CmnUtil.formatToDate(tmpFillter.to)}
              mode='date'
              is24Hour={true}
              display="spinner"
              locale='ja'
              // @ts-ignore
              onChange={(event, date) => {
                tmpIosDate = date == undefined ? '指定なし' : CmnUtil.formatDate(date);
              }} />
            <View style={{ flexDirection: 'row', paddingTop: 20 }}>
              <TouchableOpacity style={[styles.selectButton, { backgroundColor: '#4a4a4a' }]} onPress={() => { setDatepicker(undefined) }}>
                <View style={styles.center}>
                  <Text style={styles.operationBackNextButton}>キャンセル</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={() => {
                setDatepicker(undefined);
                if (datepicker == 'from') {
                  setTmpFillter({ ...tmpFillter, from: tmpIosDate });
                } else {
                  setTmpFillter({ ...tmpFillter, to: tmpIosDate });
                }
              }}>
                <View style={styles.center}>
                  <Text style={styles.operationBackNextButton}>OK</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>}
      </View>
    );
  }
  // モーダル表示
  const renderItemFillter = ({ item, index }: { item: any, index: number }) => {
    return (
      <TouchableOpacity onPress={() => selectItem(item)}
        style={[{ padding: 10, borderColor: '#bdbdbd' }, index !== modalItem!.length - 1 && { borderBottomWidth: 1 }]} >
        <Text style={[AppConst.styles.fontSizeLL, { paddingBottom: 5 }]}>{item}</Text>
      </TouchableOpacity>
    );
  }

  // モーダル内アイテム選択
  const selectItem = (item: string) => {
    if (selectModal == 'category') {
      const estimatedList = getEstimatedList(item, tmpFillter.plant);
      const index = estimatedList.indexOf(tmpFillter.estimated);
      if (index >= 0) {
        setTmpFillter({ ...tmpFillter, category: item });
      } else {
        setTmpFillter({ ...tmpFillter, category: item, estimated: '指定なし' });
      }
    } else if (selectModal == 'plant') {
      const estimatedList = getEstimatedList(tmpFillter.category, item);
      const index = estimatedList.indexOf(tmpFillter.estimated);
      if (index >= 0) {
        setTmpFillter({ ...tmpFillter, plant: item });
      } else {
        setTmpFillter({ ...tmpFillter, plant: item, estimated: '指定なし' });
      }
    } else if (selectModal == 'estimated') {
      setTmpFillter({ ...tmpFillter, estimated: item });
    } else {
    }
    setSelectModal(undefined);
  }

  // モーダル内アイテム選択
  const getEstimatedList = (p_category: string, p_plant: string) => {
    let answerDisase: string[] = [];
    let answerPest: string[] = [];
    let estimatedList: string[] = ['指定なし'];
    switch (p_plant) {
      case 'トマト':
        answerDisase = AppConst.answerDisaseTomato;
        answerPest = AppConst.answerPestTomato;
        break;
      case 'キュウリ':
        answerDisase = AppConst.answerDisaseCucumber;
        answerPest = AppConst.answerPestCucumber;
        break;
      case 'ナス':
        answerDisase = AppConst.answerDisaseEggplant;
        answerPest = AppConst.answerPestEggplant;
        break;
      case 'イチゴ':
        answerDisase = AppConst.answerDisaseStrawberry;
        answerPest = AppConst.answerPestStrawberry;
        break;
      case 'ブドウ':
        answerDisase = AppConst.answerDisaseGrapes;
        answerPest = AppConst.answerPestGrapes;
        break;
      case 'モモ':
        answerDisase = AppConst.answerDisasePeach;
        answerPest = AppConst.answerPestPeach;
        break;
      case 'タマネギ':
        answerDisase = AppConst.answerDisaseOnion;
        answerPest = AppConst.answerPestOnion;
        break;
      case 'ジャガイモ':
        answerDisase = AppConst.answerDisasePotato;
        answerPest = AppConst.answerPestPotato;
        break;
      case 'ダイズ（エダマメ）':
        answerDisase = AppConst.answerDisaseGreenSoybeans;
        answerPest = AppConst.answerPestGreenSoybeans;
        break;
      case 'ピーマン':
        answerDisase = AppConst.answerDisaseGreenPepper;
        answerPest = AppConst.answerPestGreenPepper;
        break;
      case 'カボチャ':
        answerDisase = AppConst.answerDisasePumpkin;
        answerPest = AppConst.answerPestPumpkin;
        break;
      case 'キク':
        answerDisase = AppConst.answerDisaseKiku;
        answerPest = AppConst.answerPestKiku;
        break;
      case 'ラナンキュラス':
        answerDisase = AppConst.answerDisaseRanunculus;
        answerPest = AppConst.answerPestRanunculus;
        break;
      case 'トルコギキョウ':
        answerDisase = AppConst.answerDisaseLisianthus;
        answerPest = AppConst.answerPestLisianthus;
        break;
      default:
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseTomato);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseCucumber);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseEggplant);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseStrawberry);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseGrapes);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisasePeach);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseOnion);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisasePotato);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseGreenSoybeans);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseGreenPepper);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisasePumpkin);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseKiku);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseRanunculus);
        CmnUtil.mergeList(answerDisase, AppConst.answerDisaseLisianthus);

        CmnUtil.mergeList(answerPest, AppConst.answerPestTomato);
        CmnUtil.mergeList(answerPest, AppConst.answerPestCucumber);
        CmnUtil.mergeList(answerPest, AppConst.answerPestEggplant);
        CmnUtil.mergeList(answerPest, AppConst.answerPestStrawberry);
        CmnUtil.mergeList(answerPest, AppConst.answerPestGrapes);
        CmnUtil.mergeList(answerPest, AppConst.answerPestPeach);
        CmnUtil.mergeList(answerPest, AppConst.answerPestOnion);
        CmnUtil.mergeList(answerPest, AppConst.answerPestPotato);
        CmnUtil.mergeList(answerPest, AppConst.answerPestGreenSoybeans);
        CmnUtil.mergeList(answerPest, AppConst.answerPestGreenPepper);
        CmnUtil.mergeList(answerPest, AppConst.answerPestPumpkin);
        CmnUtil.mergeList(answerPest, AppConst.answerPestKiku);
        CmnUtil.mergeList(answerPest, AppConst.answerPestRanunculus);
        CmnUtil.mergeList(answerPest, AppConst.answerPestLisianthus);
    }
    switch (p_category) {
      case '病害':
        CmnUtil.mergeList(estimatedList, answerDisase);
        break;
      case '虫害':
        CmnUtil.mergeList(estimatedList, answerPest);
        break;
      default:
        CmnUtil.mergeList(estimatedList, answerDisase);
        CmnUtil.mergeList(estimatedList, answerPest);
    }
    return estimatedList;
  }

  // 診断結果1行分描画
  const renderItem = ({ item }: { item: any }) => {
    return (
      <View>
        <TouchableOpacity style={{ padding: 10, borderBottomWidth: 1, borderColor: '#bdbdbd' }}
          onPress={() => onPressHistory(item)}
          onLongPress={() => onLongPressHistory(item)}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Image style={styles.image} source={{ uri: 'data:image/jpeg;base64,' + item.thumbnail }} />
            </View>
            <View style={{ flex: 2, paddingLeft: 10 }}>
              <Text>診断ＩＤ：{item.result_id}</Text>
              <Text>撮影日時：{item.photo_date}</Text>
              <Text>診断対象：{item.category}</Text>
              <Text>対象作物：{item.plant}</Text>
              <Text>部　位　：{item.part}</Text>
              {SettingData.getMode() === 'teacher' && <Text>正　解　：{item.answer}</Text>}
              <Text>診断結果：{CmnUtil.formatRatio(item.probability)} {item.estimated}</Text>
            </View>
          </View>
        </TouchableOpacity>
        {deleteData.indexOf(item.result_id) >= 0 && <Image style={{ position: 'absolute', width: 40, height: 40, left: 5, top: 5 }} source={IconImages.icSelected} />}
      </View>
    );
  }

  return useMemo(() => {
    return (
      <SafeAreaView style={styles.container}>
        {clientData.length > 0 && <FlatList
          ref={flatListRef}
          refreshing={refresh}
          data={clientData}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.1}
        />}
        {clientData.length == 0 && !loading && <Text style={{ marginTop: 10, marginLeft: 10 }}>
          診断結果はありません。
        </Text>}

        {showFiller && <FillterView />}
      </SafeAreaView>
    );
  }, [clientData, loading, deleteData, showFiller, tmpFillter, datepicker, selectModal, modalItem]);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  fillter: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  selectButton: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    height: 40,
    margin: 5,
    borderRadius: 10,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  operationBackNextButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  itemText: {
    marginTop: 20,
    fontSize: AppConst.styles.fontSizeS.fontSize
  },
  dropdownText: {
    textAlignVertical: 'bottom',
    width: '85%'
  },
  dropdownImage: {
    position: 'absolute',
    right: 10,
    bottom: 5,
    width: 20,
    height: 20
  },
  modal: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});
export default ScreenHistory;

import React, { useState } from 'react';
import { StyleSheet, ActivityIndicator, Text, ScrollView, TouchableOpacity, View, SafeAreaView, TextInput } from 'react-native';
import * as CmnUtil from './CmnUtil';
import * as AppConst from './AppConst';
import * as AuthControl from './AuthControl';
import * as SettingData from './SettingData';

const ScreenLogin = ({ route, navigation }: { route: any, navigation: any }) => {

  // ユーザー名
  const [user, setUser] = useState(SettingData.getLastUser());
  // パスワード
  const [password, setPassword] = useState(SettingData.getLastPassword());

  // パスワードリセットクリック
  const onClickReset = () => {
    navigation.navigate('ResetPassword');
  }

  // ログインボタンクリック
  const onClickDecision = async () => {
    if (user.length == 0) {
      CmnUtil.appAlert('ユーザー名は必須入力です。');
      return;
    }
    if (password.length == 0) {
      CmnUtil.appAlert('パスワードは必須入力です。');
      return;
    }

    CmnUtil.startLoading();
    const status = await AuthControl.login(user, password);
    switch (status) {
      case AuthControl.AuthResult.Success:
        SettingData.setLastUser(user, () => { }, () => { });
        SettingData.setAutoLoginUser(user, () => { }, () => { });
        SettingData.setLastPassword(password, () => { }, () => { });
        CmnUtil.appMsg('ログインしました。');

        if (route.params.next == 'Camera') {
          navigation.replace('Camera');
        } else if (route.params.next == 'Diaosis') {
          navigation.replace('Diaosis');
        } else {
          navigation.goBack();
        }
        break;
      case AuthControl.AuthResult.UserMismatch:
      case AuthControl.AuthResult.PasswordMismatch:
        CmnUtil.appAlert('ユーザー名またはパスワードが異なります。');
        break;
      default:
        CmnUtil.appAlert('ログインに失敗しました。');
        break;
    }
    CmnUtil.endLoading();
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contents}>
        <Text style={styles.itemText}>ユーザー名</Text>
        <TextInput
          style={styles.textInput}
          placeholder='ユーザー名を入力してください'
          secureTextEntry={false}
          textContentType='username'
          autoCapitalize='none'
          onChangeText={text => setUser(text)}
          value={user} />

        <Text style={styles.itemText}>パスワード</Text>
        <TextInput
          style={styles.textInput}
          placeholder='パスワードを入力してください'
          secureTextEntry={true}
          textContentType='password'
          autoCapitalize='none'
          onChangeText={text => setPassword(text)}
          value={password} />

        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <Text style={[{ color: 'gray' }, AppConst.styles.fontSizeS]}>パスワードをお忘れの方&nbsp;</Text>
          <TouchableOpacity
            onPress={onClickReset}>
            <Text style={[{ color: AppConst.defaultColor }, AppConst.styles.fontSizeS]}>パスワードのリセット</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity
          style={{ backgroundColor: AppConst.defaultColor, width: '100%', borderRadius: 10, height: 50, marginTop: 80 }}
          onPress={onClickDecision}>
          <View style={styles.center}>
            <Text style={styles.decisionButton}>ログイン</Text>
          </View>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemText: {
    marginTop: 20,
    fontSize: AppConst.styles.fontSizeS.fontSize
  },
  textInput: {
    height: 40,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#cccccc',
    padding: 5,
  },
  decisionButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
});
export default ScreenLogin;

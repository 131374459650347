import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View, SafeAreaView } from 'react-native';
import * as AppConst from './AppConst';
import * as SettingData from './SettingData';
import { RadioButton } from 'react-native-paper';
import * as CmnUtil from './CmnUtil';

const ScreenTeacher = ({ navigation }: { navigation: any }) => {
  const [password, setPassword] = useState(AppConst.changeModePasword);
  const [mode, setMode] = useState(SettingData.getMode);

  // 決定ボタンクリック
  const onClickDecision = () => {
    if (password === AppConst.changeModePasword) {
      SettingData.setMode(mode, () => {
        CmnUtil.appMsg('モードを設定しました。');
        navigation.goBack();
      }, () => { });
    } else {
      CmnUtil.appAlert('パスワードが正しくありません。');
    }
  }

  // ラジオボタン描画
  const ViewRadio = ({ p_mode, p_label }: { p_mode: string, p_label: string }) => {
    return (
      <View style={{ flexDirection: 'row', height: 50 }}>
        <View style={styles.radio}>
          <RadioButton value={p_mode} color={AppConst.defaultColor} />
        </View>
        <TouchableOpacity style={[styles.radio, { width: '100%' }]} activeOpacity={1.0} onPress={() => setMode(p_mode)}>
          <Text style={{ width: '100%' }}>{p_label}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contents}>
        <Text>モードを入力してください。</Text>
      </View>

      <View style={styles.contents}>
        <RadioButton.Group onValueChange={newValue => setMode(newValue)} value={mode}>
          <ViewRadio p_mode='normal' p_label='通常モード' />
          <ViewRadio p_mode='teacher' p_label='教師データ収集モード' />
        </RadioButton.Group>
      </View>

      <View style={[styles.contents, { paddingTop: 40 }]}>
        <TouchableOpacity
          style={{ backgroundColor: AppConst.defaultColor, width: '100%', borderRadius: 10, height: 50, marginTop: 5 }}
          onPress={onClickDecision}>
          <View style={styles.center}>
            <Text style={styles.decisionButton}>決定</Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  radio: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  decisionButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
});
export default ScreenTeacher;

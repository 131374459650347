import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, SafeAreaView } from 'react-native';
import Constants from 'expo-constants';
import * as CmnUtil from './CmnUtil';
import * as AppConst from './AppConst';

const ScreenSetting = ({ navigation }: { navigation: any }) => {

  let tapCount = 0;
  let timer: any = 0;

  // バージョン情報クリック
  const onClickVersion = () => {
    // 初回タップ時にタップ回数リセットタイマー起動
    if (tapCount == 0) {
      timer = setTimeout(() => tapCount = 0, 1500);
    }

    // タップ回数インクリメント
    tapCount++;

    // 1.5秒以内に5回タップされたらモード選択画面起動
    if (tapCount >= 5) {
      //navigation.navigate('Teacher');
      clearTimeout(timer);
      tapCount = 0;
    }
  }

  // ライセンス クリック
  const onClickLicense = () => {
    navigation.navigate('License');
  }

  // 利用規約 クリック
  const onClickTerm = () => {
    navigation.navigate('TermsOfUse');
  }

  return (
    <SafeAreaView style={styles.container}>
      <View>
        <Text style={styles.settingHeader}>バージョン情報</Text>
        <TouchableOpacity style={styles.settingBody} activeOpacity={1.0} onPress={onClickVersion}>
          <Text>現在のバージョン　{Constants.manifest!.version}</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>モード設定</Text>
        <TouchableOpacity style={styles.settingBody} onPress={() => navigation.navigate('Teacher')}>
          <Text>モード設定へ</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>撮影場所設定</Text>
        <TouchableOpacity style={styles.settingBody} onPress={() => navigation.navigate('Prefectures')}>
          <Text>撮影場所設定へ</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>ライセンス</Text>
        <TouchableOpacity style={styles.settingBody} onPress={onClickLicense}>
          <Text>ライセンス表示</Text>
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.settingHeader}>利用規約</Text>
        <TouchableOpacity style={styles.settingBody} onPress={onClickTerm}>
          <Text>利用規約表示</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  settingHeader: {
    backgroundColor: '#dddddd',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  settingBody: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
});
export default ScreenSetting;

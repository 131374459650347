import React, { useState, useEffect } from 'react';
import { StyleSheet, Platform, ActivityIndicator, Text, Image, TouchableOpacity, View, SafeAreaView } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from 'expo-media-library';
import * as AppConst from './AppConst';
import * as CmnUtil from './CmnUtil';
import * as Location from 'expo-location';
import { ImageEditor } from "./expo-image-editor/ImageEditor";

const ScreenCamera = ({ navigation }: { navigation: any }) => {
  // 画像選択
  const [image, setImage] = useState<string | undefined>(undefined);
  const [filename, setFilename] = useState<string>('');
  const [imageUri, setImageUri] = useState<string>('');
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [picker, setPicker] = useState<ImagePicker.ImagePickerResult | undefined>(undefined);

  // 位置情報
  const [location, setLocation] = useState<Location.LocationObject | undefined>(undefined);

  // トリミング表示
  const [cropVisible, setCropVisible] = useState(false);

  let saved = false;

  // 起動時処理
  useEffect(() => {
    (async () => {
      // カメラ権限確認
      if (Platform.OS !== 'web') {
        let result = await ImagePicker.requestCameraPermissionsAsync();
        if (result.status !== 'granted') {
          CmnUtil.appAlert('カメラへのアクセス権限がありません。');
          navigation.goBack();
          return;
        }
        result = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (result.status !== 'granted') {
          CmnUtil.appAlert('写真へのアクセス権限がありません。');
          navigation.goBack();
          return;
        }
      }

      // カメラ起動
      await showCamera();
    })();
  }, []);

  // 位置情報取得
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        let result = await Location.getForegroundPermissionsAsync();
        if (result.status === 'granted') {
          const tmpLocation = await Location.getCurrentPositionAsync({ accuracy: Location.LocationAccuracy.Balanced });
          setLocation(tmpLocation);
        }
      }
    })();
  }, []);

  // カメラ起動
  const showCamera = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [1, 1],
      quality: 1,
      exif: true,
      base64: false,
    });

    // 撮影キャンセルの場合は戻る
    if (result.cancelled) {
      // 初回のキャンセルはメニューに戻る
      if (picker === undefined) {
        navigation.goBack();
      }
      return;
    }
    saved = false;

    setPicker(result);
    if (!result.cancelled) {
      CmnUtil.startLoading();
      if (Platform.OS === 'web') {
        // web版ではファイル名が取れないので固定ファイル名
        setFilename(AppConst.filenameWeb);
      } else {
        setFilename(result.uri.split("/").reverse()[0]);
      }

      // トリミングの起動に時間が掛かるのでこのタイミングでキャンセル時用の値の用意とローディング
      const tmpImage = await CmnUtil.compressImage(result.uri);
      setImageUri(tmpImage.uri);
      setImage(tmpImage.base64);
      setImageWidth(tmpImage.width);
      setImageHeight(tmpImage.height);

      setCropVisible(true);
      CmnUtil.endLoading();
    }
  }

  // トリミングコールバック
  const cropCallback = async (uri: string) => {
    CmnUtil.startLoading();

    const tmpImage = await CmnUtil.compressImage(uri);
    setImageUri(tmpImage.uri);
    setImage(tmpImage.base64);
    setImageWidth(tmpImage.width);
    setImageHeight(tmpImage.height);

    CmnUtil.endLoading();
  }

  // トリミングキャンセルコールバック
  const cropCancelCallback = async () => {
    setCropVisible(false);
  }

  // 撮り直すボタンクリック
  const onClickRetake = async () => {
    // カメラ起動
    await showCamera();
  }

  // 保存ボタンクリック
  const onClickSave = async () => {
    if (Platform.OS !== 'web') {
      if (picker !== undefined && (!picker.cancelled)) {
        if (!saved) {
          await MediaLibrary.saveToLibraryAsync(imageUri);
          saved = true;
        }
      }
    }
    // カメラ起動
    await showCamera();
  }

  // すぐに診断ボタンクリック
  const onClickDianosis = async () => {
    if (picker !== undefined && (!picker.cancelled)) {
      if (Platform.OS !== 'web') {
        if (!saved) {
          await MediaLibrary.saveToLibraryAsync(imageUri);
          saved = true;
        }
      }
      const param: {
        filename: string,
        uri: string,
        base64: string | undefined,
        width: number,
        height: number,
        imageDate: string | undefined,
        lat: undefined | number,
        lot: undefined | number,
      } = {
        filename: filename,
        uri: imageUri,
        base64: image,
        width: imageWidth,
        height: imageHeight,
        imageDate: CmnUtil.getCurrentDateTime(),
        lat: undefined,
        lot: undefined,
      }
      if (location !== undefined) {
        param.lat = location.coords.latitude;
        param.lot = location.coords.longitude;
      }

      if (picker.exif !== undefined) {
        param.imageDate = CmnUtil.formatDateTime(picker.exif.DateTimeOriginal);
      }

      // 診断画面へ遷移
      navigation.replace('Diaosis', param);
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.photo}>
        {image && <Image style={styles.image} source={{ uri: 'data:image/jpeg;base64,' + image }} />}
      </View>
      <View style={styles.operation}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <TouchableOpacity style={[styles.button, { backgroundColor: '#4c4c4c' }]} onPress={onClickRetake}>
            <View style={styles.center}>
              <Text style={styles.buttonText}>撮影</Text>
              <Text style={styles.buttonText}>する</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.button, { backgroundColor: AppConst.defaultColor }]} onPress={onClickSave}>
            <View style={styles.center}>
              <Text style={styles.buttonText}>これで</Text>
              <Text style={styles.buttonText}>保存</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.button, { backgroundColor: '#601691' }]} onPress={onClickDianosis}>
            <View style={styles.center}>
              <Text style={styles.buttonText}>すぐに</Text>
              <Text style={styles.buttonText}>診断</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>

      {/* トリミング */}
      {image && cropVisible && <ImageEditor
        visible={cropVisible}
        onCloseEditor={() => { cropCancelCallback(); }}
        imageUri={imageUri}
        fixedCropAspectRatio={1 / 1}
        minimumCropDimensions={{ width: 100, height: 100 }}
        onEditingComplete={(cropResult: any) => { cropCallback(cropResult.uri) }}
        lockAspectRatio={false}
      />}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  photo: {
    flex: 2,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    backgroundColor: 'black',
  },
  operation: {
    flex: 1,
  },
  button: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    margin: 10,
    borderRadius: 10,
    padding: 10,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
});
export default ScreenCamera;

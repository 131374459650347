import React, { useState, useEffect } from 'react';
import { StyleSheet, Platform, Text, Image, TouchableOpacity, View, SafeAreaView, ActivityIndicator, FlatList, Dimensions, ScrollView } from 'react-native';
import IconImages from './IconImages';
import * as ImagePicker from 'expo-image-picker';
import * as AppConst from './AppConst';
import * as ApiControl from './ApiControl';
import * as CmnUtil from './CmnUtil';
import * as DbControl from './DbControl';
import * as ImageManipulator from 'expo-image-manipulator';
import * as AuthControl from './AuthControl';
import * as SettingData from './SettingData';
import ImageZoom from 'react-native-image-pan-zoom';
import { ImageEditor } from "./expo-image-editor/ImageEditor";
import { useFileUpload, FileUpload } from "use-file-upload";
import EXIF from 'exif-js';

let dianosisSuspension = false;
const ScreenDianosis = ({ route, navigation }: { route: any, navigation: any }) => {
  // 選択フェーズ
  const phaseList = { photo: '画像', target: '診断対象', plant: '作物', part: '部位', answer: '正解', dianosis: '診断' };
  const [phase, setPhase] = useState<string>(phaseList.photo);

  // 画像選択
  const [filename, setFilename] = useState<string>('');
  const [imageUri, setImageUri] = useState<string>('');
  const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageDate, setImageDate] = useState<string | undefined>(undefined);
  const [imageLat, setLat] = useState<number | undefined>(undefined);
  const [imageLot, setLot] = useState<number | undefined>(undefined);
  const [files, selectFiles] = useFileUpload();

  // 対象選択
  const targetList = { disease: '病害', pest: '虫害' };
  const [target, setTarget] = useState<string>(SettingData.getLastCategory());
  useEffect(() => {
    SettingData.setLastCategory(target, () => { }, () => { });
  }, [target]);

  // 作物選択
  const plantList = { tomato: 'トマト', strawberry: 'イチゴ', cucumber: 'キュウリ', eggplant: 'ナス', grapes: 'ブドウ', peach: 'モモ', onion: 'タマネギ', potato: 'ジャガイモ', greenSoybeans: 'ダイズ（エダマメ）', greenPepper: 'ピーマン', pumpkin: 'カボチャ', kiku: 'キク', ranunculus: 'ラナンキュラス', lisianthus: 'トルコギキョウ' };
  // 教師データ収集モードで使用できる作物か判定する
  const isReadyPlant = (p_plant: string) => {
    // 教師データ収集モードで準備中の作物
    // const preparationList = ['ブドウ', 'モモ', 'タマネギ', 'ジャガイモ', 'ダイズ（エダマメ）', 'ピーマン', 'カボチャ', 'キク', 'ラナンキュラス', 'トルコギキョウ'];
    const preparationList = ['ラナンキュラス', 'トルコギキョウ'];

    if (preparationList.indexOf(p_plant) >= 0) {
      return false;
    }

    return true;
  }
  const [plant, setPlant] = useState<string>(isReadyPlant(SettingData.getLastPlant()) ? SettingData.getLastPlant() : '');
  useEffect(() => {
    SettingData.setLastPlant(plant, () => { }, () => { });
  }, [plant]);

  // 部位選択
  const [part, setPart] = useState<string | number>(SettingData.getLastPart());
  const [partList, setPartList] = useState(['部位未選択']);
  useEffect(() => {
    SettingData.setLastPart(part.toString(), () => { }, () => { });
  }, [part]);

  // 正解選択
  const [answer, setAnswer] = useState<string | number>(SettingData.getLastAnswer());
  const [answerList, setAnswerList] = useState(['正解未選択']);
  useEffect(() => {
    SettingData.setLastAnswer(answer.toString(), () => { }, () => { });
  }, [answer]);

  // モーダル選択内容
  const [selectModal, setSelectModal] = useState<undefined | string[]>(undefined);

  // モーダル画像表示
  const [imageModal, setImageModal] = useState(false);

  // トリミング表示
  const [cropVisible, setCropVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const params = route.params;
      // 撮影画面からの遷移の場合は対象選択から始まる
      if (params !== undefined) {
        setPhase(phaseList.target);
        setFilename(params.filename);
        setImageUri(params.uri);
        setImageBase64(params.base64);
        setImageWidth(params.width);
        setImageHeight(params.height);
        setImageDate(params.imageDate);
        setLat(params.lat);
        setLot(params.lot);
      }
      if (Platform.OS !== 'web') {
        let result = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (result.status !== 'granted') {
          CmnUtil.appAlert('写真へのアクセス権限がありません。');
        }
      }
    })();
  }, []);

  // 診断中に画面遷移を戻った場合の動き
  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', () => {
      dianosisSuspension = true;
    });
    return unsubscribe;
  }, [navigation]);

  // カメラロール表示
  const showCameraRoll = async () => {
    if (Platform.OS === 'web') {
      showCameraRollWeb();
    } else {
      showCameraRollApl();
    }
  }

  const showCameraRollWeb = async () => {
    const tmpAccept: any = "image/*";
    selectFiles({ accept: tmpAccept, multiple: false }, async (fileUpload) => {
      CmnUtil.startLoading();
      setFilename(AppConst.filenameWeb);
      //@ts-ignore
      const selectFile: FileUpload = fileUpload;
      const tmpImage = await CmnUtil.compressImage(selectFile.source.toString());
      setImageUri(tmpImage.uri);
      setImageBase64(tmpImage.uri);
      setImageWidth(tmpImage.width);
      setImageHeight(tmpImage.height);
      //@ts-ignore
      EXIF.getData(selectFile.file, function () {
        const exif = EXIF.getAllTags(selectFile.file);
        const gpsInfo = CmnUtil.getGpsInfo2(exif);
        setLat(gpsInfo.lat);
        setLot(gpsInfo.lon);
        setImageDate(CmnUtil.formatDateTime(exif.DateTimeOriginal));

        setCropVisible(true);
        CmnUtil.endLoading();
      });
    });
  }

  const showCameraRollApl = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      exif: true,
      base64: false,
    });

    if (!result.cancelled) {
      CmnUtil.startLoading();
      if (Platform.OS === 'web') {
        // web版ではファイル名が取れないので固定ファイル名
        setFilename(AppConst.filenameWeb);
      } else {
        setFilename(result.uri.split("/").reverse()[0]);
      }

      // トリミングの起動に時間が掛かるのでこのタイミングでキャンセル時用の値の用意とローディング
      const tmpImage = await CmnUtil.compressImage(result.uri);
      setImageUri(tmpImage.uri);
      setImageBase64(tmpImage.base64);
      setImageWidth(tmpImage.width);
      setImageHeight(tmpImage.height);

      if (result.exif !== undefined) {
        const gpsInfo = CmnUtil.getGpsInfo(result.exif);

        setLat(gpsInfo.lat);
        setLot(gpsInfo.lon);
        setImageDate(CmnUtil.formatDateTime(result.exif.DateTimeOriginal));
      } else {
        setLat(undefined);
        setLot(undefined);
        setImageDate(CmnUtil.getCurrentDateTime());
      }

      setCropVisible(true);
      CmnUtil.endLoading();
    }
  }

  // トリミングコールバック
  const cropCallback = async (uri: string) => {
    CmnUtil.startLoading();

    const tmpImage = await CmnUtil.compressImage(uri);
    setImageUri(tmpImage.uri);
    setImageBase64(tmpImage.base64);
    setImageWidth(tmpImage.width);
    setImageHeight(tmpImage.height);

    CmnUtil.endLoading();
  }

  // トリミングキャンセルコールバック
  const cropCancelCallback = async () => {
    setCropVisible(false);
  }

  // 戻るボタン
  const onClickBuck = async () => {
    if (phase == phaseList.answer) {
      setPhase(phaseList.part);
    } else if (phase == phaseList.part) {
      setPhase(phaseList.plant);
    } else if (phase == phaseList.plant) {
      setPhase(phaseList.target);
    } else if (phase == phaseList.target) {
      setPhase(phaseList.photo);
    } else {
      navigation.goBack();
    }
  }

  // 次へ/診断ボタン
  const onClickNext = async () => {
    if (phase == phaseList.photo) {
      if (imageBase64 === undefined) {
        CmnUtil.appAlert('画像を選択してください。');
        return;
      }
      setPhase(phaseList.target);
    } else if (phase == phaseList.target) {
      if (target === '') {
        CmnUtil.appAlert('診断対象を選択してください。');
        return;
      }
      setPhase(phaseList.plant);
    } else if (phase == phaseList.plant) {
      if (plant === '') {
        CmnUtil.appAlert('作物を選択してください。');
        return;
      }
      // 部位の選択肢設定
      const neccesaryPartCrops = ['トマト','イチゴ','キュウリ','ナス'];
      let tmpPart = [''];
      if (neccesaryPartCrops.includes(plant)) {
        setPart('部位未選択');
        tmpPart = ['部位未選択'];
      }else{
        setPart('部位選択の必要はありません');
        tmpPart = ['部位選択の必要はありません'];
      }
      let answerDisase = [''];
      let answerPest = [''];
      let tmpAnser = ['正解未選択'];
      switch (plant) {
        case plantList.tomato:
          if (target == '病害') {
            tmpPart = AppConst.partTomatoDd;
            // CmnUtil.mergeList(tmpPart, AppConst.partTomatoDd);
            answerDisase = AppConst.answerDisaseTomato;
            answerPest = AppConst.answerPestTomato;
          }else{
            tmpPart = AppConst.partTomatoPest;
            // CmnUtil.mergeList(tmpPart, AppConst.partTomatoPest);
            answerDisase = AppConst.answerDisaseTomato;
            answerPest = AppConst.answerPestTomato;
          }
          break
        case plantList.cucumber:
          if (target == '病害'){
            tmpPart = AppConst.partCucumberDd;
            // CmnUtil.mergeList(tmpPart, AppConst.partCucumberDd);
            answerDisase = AppConst.answerDisaseCucumber;
            answerPest = AppConst.answerPestCucumber;
          }else{
            tmpPart = AppConst.partCucumberPest;
            // CmnUtil.mergeList(tmpPart, AppConst.partCucumberPest);
            answerDisase = AppConst.answerDisaseCucumber;
            answerPest = AppConst.answerPestCucumber;
          }
          break
        case plantList.eggplant:
          if (target == '病害'){
            tmpPart = AppConst.partEggplantDd;
            // CmnUtil.mergeList(tmpPart, AppConst.partEggplantDd);
            answerDisase = AppConst.answerDisaseEggplant;
            answerPest = AppConst.answerPestEggplant;
          }else{
            tmpPart = AppConst.partEggplantPest;
            // CmnUtil.mergeList(tmpPart, AppConst.partEggplantPest);
            answerDisase = AppConst.answerDisaseEggplant;
            answerPest = AppConst.answerPestEggplant;
          }
          break
        case plantList.strawberry:
          if (target == '病害'){
            tmpPart = AppConst.partStrawberryDd;
            // CmnUtil.mergeList(tmpPart, AppConst.partStrawberryDd);
            answerDisase = AppConst.answerDisaseStrawberry;
            answerPest = AppConst.answerPestStrawberry;
          }else{
            tmpPart = AppConst.partStrawberryPest;
            // CmnUtil.mergeList(tmpPart, AppConst.partStrawberryPest);
            answerDisase = AppConst.answerDisaseStrawberry;
            answerPest = AppConst.answerPestStrawberry;
          }
          break;
        case plantList.grapes:
          // CmnUtil.mergeList(tmpPart, AppConst.partGrapes);
          answerDisase = AppConst.answerDisaseGrapes;
          answerPest = AppConst.answerPestGrapes;
          break;
        case plantList.peach:
          // CmnUtil.mergeList(tmpPart, AppConst.partPeach);
          answerDisase = AppConst.answerDisasePeach;
          answerPest = AppConst.answerPestPeach;
          break;
        case plantList.onion:
          // CmnUtil.mergeList(tmpPart, AppConst.partOnion);
          answerDisase = AppConst.answerDisaseOnion;
          answerPest = AppConst.answerPestOnion;
          break;
        case plantList.potato:
          // CmnUtil.mergeList(tmpPart, AppConst.partPotato);
          answerDisase = AppConst.answerDisasePotato;
          answerPest = AppConst.answerPestPotato;
          break;
        case plantList.greenSoybeans:
          // CmnUtil.mergeList(tmpPart, AppConst.partGreenSoybeans);
          answerDisase = AppConst.answerDisaseGreenSoybeans;
          answerPest = AppConst.answerPestGreenSoybeans;
          break;
        case plantList.greenPepper:
          // CmnUtil.mergeList(tmpPart, AppConst.partGreenPepper);
          answerDisase = AppConst.answerDisaseGreenPepper;
          answerPest = AppConst.answerPestGreenPepper;
          break;
        case plantList.pumpkin:
          // CmnUtil.mergeList(tmpPart, AppConst.partPumpkin);
          answerDisase = AppConst.answerDisasePumpkin;
          answerPest = AppConst.answerPestPumpkin;
          break;
        case plantList.kiku:
          // CmnUtil.mergeList(tmpPart, AppConst.partKiku);
          answerDisase = AppConst.answerDisaseKiku;
          answerPest = AppConst.answerPestKiku;
          break;
        case plantList.ranunculus:
          // CmnUtil.mergeList(tmpPart, AppConst.partRanunculus);
          answerDisase = AppConst.answerDisaseRanunculus;
          answerPest = AppConst.answerPestRanunculus;
          break;
        case plantList.lisianthus:
          // CmnUtil.mergeList(tmpPart, AppConst.partLisianthus);
          answerDisase = AppConst.answerDisaseLisianthus;
          answerPest = AppConst.answerPestLisianthus;
          break;
        default:
          console.log("作物不一致");
      }
      setPartList(tmpPart);
      let index = tmpPart.indexOf(SettingData.getLastPart());
      if (index < 0) {
        if (neccesaryPartCrops.includes(plant)) {
          setPart('部位未選択');
        }else{
          setPart('部位選択の必要はありません');
        }
      }

      // 正解の選択肢設定
      switch (target) {
        case targetList.disease:
          CmnUtil.mergeList(tmpAnser, answerDisase);
          break;
        case targetList.pest:
          CmnUtil.mergeList(tmpAnser, answerPest);
          break;
        default:
          console.log("対象不一致");
      }
      setAnswerList(tmpAnser);
      index = tmpAnser.indexOf(SettingData.getLastAnswer());
      if (index < 0) {
        setAnswer('正解未選択');
      }

      setPhase(phaseList.part);
    } else if (phase == phaseList.part) {
      if (SettingData.getMode() === 'teacher') {
        if (["トマト","イチゴ","キュウリ","ナス"].includes(plant)){
          if (part === '部位未選択'){
            CmnUtil.appAlert('部位選択は必須です');
            return;
          }
        }
        setPhase(phaseList.answer);
      } else {
        if (["トマト","イチゴ","キュウリ","ナス"].includes(plant)){
          if (part === '部位未選択'){
            CmnUtil.appAlert('部位選択は必須です');
            return;
          }
        }
        setAnswer('正解未選択');
        // 診断して結果画面へ
        dianosis();
      }
    } else {
      if (["トマト","イチゴ","キュウリ","ナス"].includes(plant)){
        if (part === '部位未選択'){
          CmnUtil.appAlert('部位選択は必須です');
          return;
        }
      }
      // 診断して結果画面へ
      dianosis();
    }
  }

  // 診断
  const dianosis = async () => {
    // ローディング表示
    CmnUtil.startLoading();
    dianosisSuspension = false;

    if (!await AuthControl.refreshToken()) {
      navigation.navigate('Login', { next: 'back' });
      CmnUtil.endLoading();
      return;
    }

    // パラメータ設定
    const p_attributes: ApiControl.Attributes = {
      category: target,
      plant: plant,
    };
    if (part !== '部位未選択') {
      p_attributes['part'] = part.toString();
    }

    const p_image: ApiControl.Image = {
      filename: filename,
      data: imageBase64!,
      datetime: imageDate!.replace(/\//g, '-')
    };
    if (imageLat !== undefined) {
      p_image['lat'] = imageLat;
    }
    if (imageLot !== undefined) {
      p_image['lon'] = imageLot;
    }
    if (answer !== '正解未選択') {
      p_image['answer'] = answer.toString();
    }

    // 診断実行
    ApiControl.dianosis(p_attributes, p_image, async (response: any) => {
      if (response.status !== 0) {
        CmnUtil.endLoading();
        CmnUtil.appAlert('通信に失敗しました。通信環境を確認の上、再度お試しください。');
        return;
      }
      if (response.assets === undefined || response.assets.length === 0
        || response.assets[0].images === undefined || response.assets[0].images.length === 0) {
        CmnUtil.endLoading();
        CmnUtil.appAlert('システム障害です。時間をおいて、再度お試しください。');
        return;
      }

      if (response.assets[0].images[0].results === undefined
        || response.assets[0].images[0].results.length === 0) {
        CmnUtil.endLoading();
        CmnUtil.appAlert('病虫害は見つかりませんでした。撮影しなおしてください。');
        return;
      }

      // 応答データの成形
      response = moldingResponse(response, imageWidth, imageHeight);

      // 参考画像、農薬情報がundefinedの場合は空配列として成形
      for (let i = 0; i < response.assets[0].images[0].results.length; i++) {
        for (let j = 0; j < response.assets[0].images[0].results[i].candidates.length; j++) {
          if (response.assets[0].images[0].results[i].candidates[j].pesticide == undefined) {
            response.assets[0].images[0].results[i].candidates[j].pesticide = [];
          }
          if (response.assets[0].images[0].results[i].candidates[j].photos == undefined) {
            response.assets[0].images[0].results[i].candidates[j].photos = [];
          }
        }
      }

      const info = {
        filename: filename,
        data: imageUri,
        thumbnail: '',
        lat: imageLat,
        lot: imageLot,
        answer: answer,
        category: target,
        plant: plant,
        part: part,
        photoW: imageWidth,
        photoH: imageHeight,
        photo_date: imageDate,
      }
      if (Platform.OS === 'web') {
        // 結果画面へ遷移
        CmnUtil.endLoading();
        navigation.replace('Result', { info: info, results: response.assets[0].images[0].results });
      } else {
        // 履歴用のサムネ作成
        const manipResult = await ImageManipulator.manipulateAsync(
          imageUri,
          [{ resize: { width: AppConst.thumbnailWidth } }],
          { compress: AppConst.thumbnailCompress, format: ImageManipulator.SaveFormat.JPEG, base64: true }
        );
        info.thumbnail = manipResult.base64!;

        // 画像をキャッシュディレクトリからドキュメントディレクトリにコピー
        await CmnUtil.makeDirectoryAsync('Image');
        const saveFilename = 'Image/' + (new Date()).getTime() + '.jpg';
        info.data = await CmnUtil.copyFile(info.data, saveFilename);

        // DBに格納
        DbControl.setDianosisResult(info, response, () => {
          // 結果画面へ遷移
          if (!dianosisSuspension) {
            CmnUtil.endLoading();
            navigation.replace('Result', { info: info, results: response.assets[0].images[0].results });
          }
        },
          () => {
            CmnUtil.endLoading();
          }
        );
      }
    },
      () => {
        CmnUtil.endLoading();
        CmnUtil.appAlert('通信に失敗しました。通信環境を確認の上、再度お試しください。');
      });
  }

  // 応答データの成形
  const moldingResponse = (response: any, width: number, height: number) => {
    let tmpResponse: any = JSON.parse(JSON.stringify(response));
    let bboxCount = 0;
    let noBoxCount = 0;
    let tmpCandidates: any = [];

    // 結果の診断
    for (let i = 0; i < response.assets[0].images[0].results.length; i++) {
      const result = response.assets[0].images[0].results[i];
      if (result.bbox != undefined && result.bbox.length > 0) {
        bboxCount++;
      } else {
        noBoxCount++;
        tmpCandidates = tmpCandidates.concat(result.candidates)
      }
    }

    // bboxなしの結果を統合(信頼度の高い順に統合する)
    // 条件：bboxなしの結果が複数存在する場合
    if (noBoxCount >= 2) {
      let tmpResults: any = [];
      let concat = false;
      for (let i = 0; i < response.assets[0].images[0].results.length; i++) {
        let result = response.assets[0].images[0].results[i];
        if (result.bbox != undefined && result.bbox.length > 0) {
          tmpResults.push(result);
        } else if (!concat) {
          result.candidates = tmpCandidates.sort((first: any, second: any) => second.probability - first.probability);
          tmpResults.push(result);
          concat = true;
        } else {
          // 統合済みのデータなのでskip
        }
      }
      tmpResponse.assets[0].images[0].results = tmpResults;
    }

    // フルサイズのbboxを仮想的に設定
    // 条件：bboxが存在する結果と存在しない結果が混在している場合
    if (bboxCount > 0 && noBoxCount > 0) {
      for (let i = 0; i < tmpResponse.assets[0].images[0].results.length; i++) {
        const result = tmpResponse.assets[0].images[0].results[i];
        if (result.bbox != undefined && result.bbox.length > 0) {
        } else {
          tmpResponse.assets[0].images[0].results[i].bbox = [0, 0, width, height];
        }
      }
    }

    // 信頼度の高い順に並べ替え
    for (let i = 0; i < tmpResponse.assets[0].images[0].results.length; i++) {
      tmpResponse.assets[0].images[0].results[i].candidates = tmpResponse.assets[0].images[0].results[i].candidates.sort((first: any, second: any) => second.probability - first.probability);
    }
    return tmpResponse;
  }

  // 選択箇所の画面
  const viewSelect = () => {
    if (phase == phaseList.photo) {
      // 画像選択
      return (
        <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={showCameraRoll}>
          <Image style={styles.selectImage} source={IconImages.icPictureSelect} />
        </TouchableOpacity>
      );
    } else if (phase == phaseList.target) {
      // 対象選択
      return (
        <View style={{ flex: 1, flexDirection: 'row', }}>
          <TouchableOpacity style={[styles.selectButton, target == targetList.disease ? { backgroundColor: AppConst.defaultColor } : styles.unselected]} onPress={() => setTarget(targetList.disease)}>
            <Image style={styles.selectImage} source={IconImages.icSickLeaf} />
          </TouchableOpacity>
          <TouchableOpacity style={[styles.selectButton, target == targetList.pest ? { backgroundColor: AppConst.defaultColor } : styles.unselected]} onPress={() => setTarget(targetList.pest)}>
            <Image style={styles.selectImage} source={IconImages.icWorm} />
          </TouchableOpacity>
        </View>
      );
    } else if (phase == phaseList.plant) {
      // 対象選択
      return (
        <ScrollView style={{ flex: 1, }}>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.tomato ? { backgroundColor: '#cc2f18' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.tomato)}>
              <Image style={styles.selectImage} source={IconImages.icTomato} />
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.strawberry ? { backgroundColor: '#dd0977' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.strawberry)}>
              <Image style={styles.selectImage} source={IconImages.icStrawberry} />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.cucumber ? { backgroundColor: '#0d8f18' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.cucumber)}>
              <Image style={styles.selectImage} source={IconImages.icCucumber} />
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.eggplant ? { backgroundColor: '#601691' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.eggplant)}>
              <Image style={styles.selectImage} source={IconImages.icEggplant} />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.grapes ? { backgroundColor: '#56256e' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.grapes)}>
              <Image style={styles.selectImage} source={IconImages.icGrapes} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.peach ? { backgroundColor: '#d83473' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.peach)}>
              <Image style={styles.selectImage} source={IconImages.icPeach} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.onion ? { backgroundColor: '#e17b34' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.onion)}>
              <Image style={styles.selectImage} source={IconImages.icOnion} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.potato ? { backgroundColor: '#ac6b25' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.potato)}>
              <Image style={styles.selectImage} source={IconImages.icPotato} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.greenSoybeans ? { backgroundColor: '#65ab31' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.greenSoybeans)}>
              <Image style={styles.selectImage} source={IconImages.icGreenSoybeans} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.greenPepper ? { backgroundColor: '#009944' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.greenPepper)}>
              <Image style={styles.selectImage} source={IconImages.icGreenPepper} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.pumpkin ? { backgroundColor: '#417038' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.pumpkin)}>
              <Image style={styles.selectImage} source={IconImages.icPumpkin} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.kiku ? { backgroundColor: '#fcc800' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.kiku)}>
              <Image style={styles.selectImage} source={IconImages.icKiku} />
              {/* <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} /> */}
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 80 }}>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.ranunculus ? { backgroundColor: '#ef857d' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.ranunculus)}>
              <Image style={styles.selectImage} source={IconImages.icRanunculus} />
              <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} />
            </TouchableOpacity>
            <TouchableOpacity style={[styles.selectButton, styles.selectPlant, plant == plantList.lisianthus ? { backgroundColor: '#47266e' } : styles.unselected, { marginBottom: 5 }]} onPress={() => setPlantWrap(plantList.lisianthus)}>
              <Image style={styles.selectImage} source={IconImages.icLisianthus} />
              <Image style={[styles.selectImage, { position: 'absolute' }]} source={IconImages.icInPreparation} />
            </TouchableOpacity>
          </View>
          <View style={{ height: 5 }} />
        </ScrollView>
      );
    } else if (phase == phaseList.part) {
      // 部位選択
      return (
        <TouchableOpacity style={{ width: '90%', borderBottomWidth: 1, flexDirection: 'row', marginLeft: '5%', marginRight: '5%' }}
          onPress={() => { setSelectModal(partList) }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{part}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>
      );
    } else if (phase == phaseList.answer) {
      // 正解選択
      return (
        <TouchableOpacity style={{ width: '90%', borderBottomWidth: 1, flexDirection: 'row', marginLeft: '5%', marginRight: '5%' }}
          onPress={() => { setSelectModal(answerList) }}>
          <Text style={[AppConst.styles.fontSizeLL, styles.dropdownText]} numberOfLines={2}>{answer}</Text>
          <Image style={styles.dropdownImage} source={IconImages.icDropdown} />
        </TouchableOpacity>
      );
    } else {
      return (<></>);
    }
  }

  // 次へ/診断ボタン
  const viewNext = () => {
    if (phase == phaseList.photo) {
      return ('次へ');
    } else if (phase == phaseList.target) {
      return ('次へ');
    } else if (phase == phaseList.plant) {
      return ('次へ');
    } else if (phase == phaseList.part) {
      if (SettingData.getMode() === 'teacher') {
        return ('次へ');
      } else {
        return ('診断する');
      }
    } else if (phase == phaseList.answer) {
      return ('診断する');
    } else {
      return (<></>);
    }
  }

  // setPlantのラップ関数
  const setPlantWrap = (p_plant: string) => {
    if (isReadyPlant(p_plant)) {
      setPlant(p_plant);
    }
  }

  // モーダル表示
  const renderItem = ({ item, index }: { item: any, index: number }) => {
    return (
      <TouchableOpacity onPress={() => selectItem(item)}
        style={[{ padding: 10, borderColor: '#bdbdbd' }, index !== selectModal!.length - 1 && { borderBottomWidth: 1 }]} >
        <Text style={[AppConst.styles.fontSizeLL, { paddingBottom: 5 }]}>{item}</Text>
      </TouchableOpacity>
    );
  }

  // モーダル内アイテム選択
  const selectItem = (item: string) => {
    if (phase == phaseList.part) {
      setPart(item);
    } else if (phase == phaseList.answer) {
      setAnswer(item);
    } else {
    }
    setSelectModal(undefined);
  }

  // 全体画面
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.photo}>
        {imageBase64 && <TouchableOpacity style={{ width: '100%', height: '100%' }} onPress={() => { setImageModal(true) }} activeOpacity={0.9}>
          <Image style={[styles.image, { backgroundColor: 'black' }]} source={{ uri: imageUri }} />
        </TouchableOpacity>}
        {!imageBase64 && <Image style={[styles.image, { resizeMode: 'center' }]} source={IconImages.icPicture} />}
        {imageDate && <Text style={styles.shootingDate}>撮影日時：{imageDate}</Text>}
      </View>
      <View style={styles.operation}>
        <View style={styles.selection}>
          <View style={styles.center}>
            <Text style={styles.operationMessage}>{phase}を選択してください</Text>
          </View>
          <View style={{ flex: 4 }}>
            {viewSelect()}
          </View>
        </View>
        <View style={styles.backNext}>
          <TouchableOpacity style={[styles.selectButton, { backgroundColor: '#4a4a4a', }]} onPress={onClickBuck}>
            <View style={styles.center}>
              <Text style={styles.operationBackNextButton}>戻る</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.selectButton, { backgroundColor: AppConst.defaultColor }]} onPress={onClickNext}>
            <View style={styles.center}>
              <Text style={styles.operationBackNextButton}>{viewNext()}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {selectModal && <TouchableOpacity style={styles.modal} activeOpacity={0.7} onPress={() => { setSelectModal(undefined) }}>
        <FlatList
          style={{ width: '90%', height: '90%', marginTop: 100, marginBottom: 100, backgroundColor: 'white', borderRadius: 5 }}
          data={selectModal}
          renderItem={renderItem}
          keyExtractor={(item: any, index: number) => index.toString()}
        />
      </TouchableOpacity>}

      {/* 画像拡大表示 */}
      {imageModal && <View style={styles.modal} >
        <ImageZoom cropWidth={Dimensions.get('window').width}
          cropHeight={Dimensions.get('window').height}
          imageWidth={Dimensions.get('window').width}
          imageHeight={Dimensions.get('window').height}>
          <Image style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }}
            resizeMode='contain'
            source={{ uri: imageUri }} />
        </ImageZoom>
        <TouchableOpacity style={{ position: 'absolute', top: 30, right: 30 }} onPress={() => { setImageModal(false) }}>
          <Image style={{ width: 30, height: 30 }}
            resizeMode='contain'
            source={IconImages.icClose} />
        </TouchableOpacity>
      </View>}

      {/* トリミング */}
      {imageBase64 && cropVisible && <ImageEditor
        visible={cropVisible}
        onCloseEditor={() => { cropCancelCallback(); }}
        imageUri={imageUri}
        fixedCropAspectRatio={1 / 1}
        minimumCropDimensions={{ width: 100, height: 100 }}
        onEditingComplete={(cropResult: any) => { cropCallback(cropResult.uri) }}
        lockAspectRatio={false}
      />}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  photo: {
    flex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  shootingDate: {
    position: 'absolute',
    color: 'white',
    marginLeft: 10,
    bottom: 10,
    textShadowColor: 'black',
    textShadowRadius: 2,
  },
  operation: {
    flex: 1,
  },
  selection: {
    flex: 3,
  },
  backNext: {
    flex: 1,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderColor: '#bdbdbd',
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectButton: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    margin: 10,
    borderRadius: 10,
    padding: 10,
  },
  selectPlant: {
    height: 70,
  },
  unselected: {
    backgroundColor: '#bdbdbd'
  },
  selectImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  operationMessage: {
    color: AppConst.defaultColor,
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  operationBackNextButton: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
  },
  dropdownText: {
    textAlignVertical: 'bottom',
    width: '85%'
  },
  dropdownImage: {
    position: 'absolute',
    right: 10,
    bottom: 5,
    width: 20,
    height: 20
  },
  modal: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});
export default ScreenDianosis;

import React from 'react';
import { StyleSheet, Text, ScrollView, View, SafeAreaView } from 'react-native';

const ScreenTermsOfUse = ({ navigation }: { navigation: any }) => {

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.contents}>
        <TermsSentence />
      </ScrollView>
    </SafeAreaView>
  );
}

// 規約文章
export const TermsSentence = () => {
  return (
    <View>
      <Text style={styles.paragraph}>（診断結果の責任所在）</Text>
      <Text>農研機構が提供する病虫害AI診断APIは、試験研究段階であり、画像判別と確率予測に基づくアドバイスを行うものです。実際の作物病虫害の発生の確度を保証できません。実際の植物管理については、利用者の自己責任とし、農研機構は利用者がおこなった管理行為によるいかなる帰結にも責任を負いません。</Text>

      <Text style={styles.paragraph}>（通信料）</Text>
      <Text>病虫害AI診断API使用に関連する通信費・電力は利用者の負担になります。非常に大きな画像を大量に送信する際にはご注意ください。</Text>

      <Text style={styles.paragraph}>（システム不具合に関する責任所在）</Text>
      <Text>農研機構が提供する病虫害診断APIは、予告なくバージョンアップやシステムの改良、対象作目・病虫害の変更・追加、一時的なサービス停止を行います。この結果生じる利用者の不利益に関して、農研機構は一切責任を負いません。</Text>

      <Text style={styles.paragraph}>（画像、利用者情報の収集）</Text>
      <Text>利用者が送信した画像は、農研機構内に蓄積し、農研機構及び農研機構から許諾を得た第三者（以下「許諾先」と言います。）において、病虫害診断精度の向上に利用することを無償で許諾いただいたものといたします。また、画像と同時に収集される利用者のその他の情報（送信位置・日時等）についても、個人が特定されない形で農研機構が収集し、農研機構及び許諾先においてさらなる病虫害診断精度の向上に利用する場合があります。ご了承ください。</Text>

      <Text style={styles.paragraph}>なお農研機構及び許諾先は、病虫害診断サービスをエンドユーザに対して提供することがあります。ご了承ください。</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contents: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  paragraph: {
    marginTop: 20,
  }
});
export default ScreenTermsOfUse;

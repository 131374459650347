import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, Dimensions, Image, TouchableOpacity, View, SafeAreaView } from 'react-native';
import IconImages from './IconImages';
import * as AppConst from './AppConst';
import * as ApiControl from './ApiControl';
import * as CmnUtil from './CmnUtil';
import * as SettingData from './SettingData';
import ImageZoom from 'react-native-image-pan-zoom';


let Ppd_dictSuspension = false;
const ScreenResult = ({ route, navigation }: { route: any, navigation: any }) => {

  // 診断情報
  const [info, setInfo] = useState<any>(undefined);
  // 診断結果情報
  const [results, setResults] = useState<any>(undefined);
  // 選択グループ（矩形の番号）
  const [resultIndex, setResultIndex] = useState(0);
  // 選択順位（タブの番号）
  const [candidateIndex, setCandidateIndex] = useState(0);
  const [candidate, setCandidate] = useState<{ key: string; title: string; name: string; photo: string}[]>([]);
  // タブ再描画用変数
  const [candidateFlush, setCandidateFlush] = useState(false);
  // 画像描画領域サイズ
  const [imageViewSize, setImageViewSize] = useState({ width: 0, height: 0 });
  // 診断情報表示フラグ
  const [dianosisInfo, setDianosisInfo] = useState(true);
  // モーダル画像表示
  const [imageModal, setImageModal] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (route.params.info.part == '部位選択の必要はありません') {
      route.params.info.part = 'なし'
    }
    setInfo(route.params.info);
    setResults(route.params.results);
    const tmp_candidate = [];
    if(route.params.results[resultIndex].candidates[0].length != undefined){
      for (let i = 0; i < route.params.results[resultIndex].candidates[0].length; i++) {
        const tmp = route.params.results[resultIndex].candidates[0][i];
        tmp_candidate[i] = { key: i.toString(), title: CmnUtil.formatRatio(tmp.probability), name: tmp.estimated, photo: tmp.photos };
      }
    }else{
      for (let i = 0; i < route.params.results[resultIndex].candidates.length; i++) {
        const tmp = route.params.results[resultIndex].candidates[i];
        tmp_candidate[i] = { key: i.toString(), title: CmnUtil.formatRatio(tmp.probability), name: tmp.estimated, photo: tmp.photos };
      }
    }
    setCandidate(tmp_candidate);
    setCandidateFlush(true);
  }, []);

  useEffect(() => {
    // ヘッダの診断情報表示ボタン
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ marginRight: 20 }}
          onPress={() => setDianosisInfo(!dianosisInfo)}>
          {dianosisInfo && <Image style={{ width: 30, height: 30 }} resizeMode='cover' source={IconImages.icImageInfoInvisible} />}
          {!dianosisInfo && <Image style={{ width: 30, height: 30 }} resizeMode='cover' source={IconImages.icImageInfoVisible} />}
        </TouchableOpacity>
      ),
    });
  }, [dianosisInfo]);
  
  const resultView = () => {
    for (let i = 0; i < candidate.length; i++){
      if (candidate[i].photo === 'no image'){
        candidate[i].photo = IconImages.noImage
      }
    }
    return (
      <View style={styles.container}>
        <View>
          <Text style={ styles.insectInfo }>該当する病虫害を紹介します。病虫害をタップして下さい。</Text>
        </View>
        {candidate.map((item) => {
          return (
            <TouchableOpacity key={item.key} onPress={() => {ppd_dict(item.name, info.plant)}} style={styles.row}>
              <Image style={styles.picture} resizeMode='contain' source={{uri: "data:image/jpeg;base64," + item.photo}} />
              <View style={styles.column}> 
                <View style={styles.insectName}>
                  <Text style={{ marginTop: 5, fontSize: 16, color: '#999999' }}>{item.title}</Text>
                  <Text style={{ fontSize: 18}}>{item.name}</Text>
                  <Text style={{ fontSize: 14}}>{info.plant}</Text>
                </View>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  const ppd_dict = async (name: string, plant: string) => {
    
    // ローディング表示
    CmnUtil.startLoading();
    Ppd_dictSuspension = false;

    // 診断実行
    ApiControl.ppd_dict(name, plant, async (response: any) => {
        // 結果画面へ遷移
        if (name == "健全") {
          CmnUtil.endLoading();
          CmnUtil.appAlert('健全な項目です。他の項目をお試しください。');
        }else if (response.ppd_dict.ppd_dict.length == 0){
          CmnUtil.endLoading();
          CmnUtil.appAlert('詳細な情報がありません。他の項目をお試しください。');
        }else{
          CmnUtil.endLoading();
          navigation.navigate('Detail', { dict_results: response.ppd_dict, pictures_result: response.ppd_dict_pictures, pesticide_result: response.pesticide_list, plant: plant, name: name });
        }
    },
      () => {
        CmnUtil.endLoading();
        CmnUtil.appAlert('通信に失敗しました。通信環境を確認の上、再度お試しください。');
      });
  }

  // 矩形表示
  const bboxView = () => {
    if (results == undefined) {
      return (<></>);
    }
    const tmp_bboxView = [];

    // 座標0,0に近いものに並べ替え
    const sortIndexList = sortBBox();

    for (let i = 0; i < results.length; i++) {

      const sortIndex = sortIndexList[i];
      const bbox = results[sortIndex].bbox;
      if (bbox != undefined && imageViewSize.width != 0
        && bbox.length != 0 && bbox[0] != undefined && bbox[0] != null) {
        // 座標計算
        const baseAsp = imageViewSize.width / imageViewSize.height;
        const imgAsp = info.photoW / info.photoH;
        let rete = 1;
        let xMargin = 0;
        let yMargin = 0;
        if (baseAsp < imgAsp) {
          // 上下に余白
          rete = imageViewSize.width / info.photoW;
          yMargin = (imageViewSize.height - info.photoH * rete) / 2;
        } else {
          // 左右に余白
          rete = imageViewSize.height / info.photoH;
          xMargin = (imageViewSize.width - info.photoW * rete) / 2;
        }
        const tmp_width = (bbox[2] - bbox[0]) * rete;
        const tmp_height = (bbox[3] - bbox[1]) * rete;
        const tmp_left = bbox[0] * rete + xMargin;
        const tmp_top = bbox[1] * rete + yMargin;

        // 矩形View
        tmp_bboxView.push(
          <TouchableOpacity key={sortIndex} style={[resultIndex == sortIndex ? { borderColor: 'blue' } : { borderColor: 'gray' },
          { borderWidth: 2, height: tmp_height, width: tmp_width, position: 'absolute', left: tmp_left, top: tmp_top }]}
            onPress={() => changeResultIndex(sortIndex)}
            ></TouchableOpacity>
        )
      }
    }

    return <>{tmp_bboxView}</>;
  }

  // bboxを座標0,0に近いものから並べ替えたIndexを返却
  const sortBBox = () => {
    const indexList = [];
    let tmpIndex = 0;
    for (let i = 0; i < results.length; i++) {
      indexList.push(i);
    }
    indexList.sort((a, b) => {
      let comp = 0;
      if (results[a].bbox == undefined || results[b].bbox == undefined ||
        results[a].bbox.length == 0 || results[b].bbox.length == 0 ||
        results[a].bbox[0] == undefined || results[b].bbox[0] == undefined ||
        results[a].bbox[0] == null || results[b].bbox[0] == null
      ) {
        return comp;
      }

      comp = results[a].bbox[0] - results[b].bbox[0];
      if (comp == 0) {
        comp = results[a].bbox[1] - results[b].bbox[1];
      }
      return comp;
    });

    return indexList;
  }

  // 選択グループ変更
const changeResultIndex = (index: number) => {
  setResultIndex(index);
  const tmp_candidate = [];
  for (let i = 0; i < results[index].candidates[index].length; i++) {
  const tmp = results[index].candidates[index][i];
  tmp_candidate[i] = { key: i.toString(), title: CmnUtil.formatRatio(tmp.probability), name: tmp.estimated, photo: tmp.photos };
  }
  setCandidate(tmp_candidate);
  setCandidateFlush(false);
  setCandidateFlush(true);
  setCandidateIndex(0);
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={styles.photo} onLayout={(e) => { setImageViewSize({ width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height }) }}>
          {info && <TouchableOpacity onPress={() => { setImageModal(info.data) }} activeOpacity={0.9}><Image style={styles.image} source={{ uri: info.data }} /></TouchableOpacity>}
          {dianosisInfo && info && <View style={styles.dianosisInfo}>
            {info.id && <Text style={styles.dianosisInfoText}>診断ＩＤ：{info.id}</Text>}
            {info.photo_date && <Text style={styles.dianosisInfoText}>撮影日時：{info.photo_date}</Text>}
            {info.category && <Text style={styles.dianosisInfoText}>診断対象：{info.category}</Text>}
            {info.plant && <Text style={styles.dianosisInfoText}>対象作物：{info.plant}</Text>}
            {info.part && <Text style={styles.dianosisInfoText}>部　位　：{info.part}</Text>}
            {info.answer && SettingData.getMode() === 'teacher' && <Text style={styles.dianosisInfoText}>正　解　：{info.answer}</Text>}
          </View>}
          {bboxView()}
        </View>

        <View>
          {resultView()}
        </View>

      </ScrollView>
      {imageModal && <View style={styles.modal} >
        <ImageZoom cropWidth={Dimensions.get('window').width}
          cropHeight={Dimensions.get('window').height}
          imageWidth={Dimensions.get('window').width}
          imageHeight={Dimensions.get('window').height}>
          <Image style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }}
            resizeMode='contain'
            source={{ uri: imageModal }} />
        </ImageZoom>
        <TouchableOpacity style={{ position: 'absolute', top: 30, right: 30 }} onPress={() => { setImageModal(undefined) }}>
          <Image style={{ width: 30, height: 30 }}
            resizeMode='contain'
            source={IconImages.icClose} />
        </TouchableOpacity>
      </View>}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  photo: {
    height: 350,
    backgroundColor: 'black',
  },
  image: {
    width: '100%',
    height: 350,
    resizeMode: 'contain',
  },
  dianosisInfo: {
    position: 'absolute',
    marginLeft: 10,
    bottom: 10,
  },
  dianosisInfoText: {
    color: 'white',
    fontSize: AppConst.styles.fontSizeM.fontSize,
    textShadowColor: 'black',
    textShadowRadius: 2,
  },
  detail: {
    flex: 1,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  picture: {
    width: '60%',
    height: 175,
    margin: 5,
    backgroundColor: 'black',
  },
  insectInfo: {
    backgroundColor: '#B3B2B2',
    fontSize: 16,
    textAlign: 'center',
  },
  insectName: {
    marginEnd: 5,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    borderBottomWidth: 2,
    borderBottomColor: "black",
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
});

export default ScreenResult;
